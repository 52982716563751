import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useMeQuery as useMeQueryType } from "./__generated__/useMeQuery.graphql"

export const meQuery = graphql`
  query useMeQuery {
    me {
      firstName
      lastName
      email
      role {
        name
      }
      themeMode
      chosenTopics
      learningLanguage
      appLanguage
    }
  }
`
const useMeQuery = () => {
  const { me } = useLazyLoadQuery<useMeQueryType>(meQuery, {})
  return me
}

export { useMeQuery }
