import { searchQuery } from "@/modules/search/services/__generated__/searchQuery.graphql"
import { SearchQuery } from "@/modules/search/services/search-query"
import { useQueryLoader } from "react-relay"

const useSearchLoader = () => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<searchQuery>(SearchQuery)

  return { queryReference, loadQuery, disposeQuery }
}

export default useSearchLoader
