import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { cn } from "@/utils/utils"
import { format } from "date-fns"
import { CalendarIcon, X } from "lucide-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { BiRefresh } from "react-icons/bi"

type FilterProps = {
  onRefresh: () => void
  onSearch: (value: string) => void
}

const Filter = ({ onRefresh, onSearch }: FilterProps) => {
  const {t } = useTranslation()
  const [dateFilter, setDateFilter] = useState<Date | undefined>(undefined)
  const [, setSourceTypeFilter] = useState<string | undefined>(undefined)
  
  return (
    <div className="flex space-x-4 mb-4">
    <Input
      type="text"
      placeholder={t("Enter search keyword")}
      onChange={(e) => onSearch(e.target.value)}
      className="w-1/3 mb-4"
    />
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !dateFilter && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {dateFilter ? format(dateFilter, "PPP") : <span>Pick a date</span>}
          {dateFilter && (
          <X
            className="ml-auto h-4 w-4 opacity-50 hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation()
              setDateFilter(undefined)
            }}
          />
      )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={dateFilter}
          onSelect={setDateFilter}
          initialFocus
        />
      </PopoverContent>
    </Popover>
    <Select onValueChange={(value) => setSourceTypeFilter(value)}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Filter by source" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All sources</SelectItem>
        <SelectItem value="out-app">Out-app</SelectItem>
        <SelectItem value="in-app">In-app</SelectItem>
      </SelectContent>
    </Select>
    <div className="mb-5 text-center">
        <Button onClick={() => {
          onRefresh()
        }}>
          <BiRefresh className="mr-2" size={20} />
          Refresh</Button>
      </div>
  </div>
  )
}

export { Filter }
