import { ReactHTML } from "react"
import { createPortal } from "react-dom"
import { styled } from "styled-components"

interface ModalProps extends React.HTMLAttributes<ReactHTML> {
  isOpen: boolean
  closeModal: () => void
}

function Modal({ isOpen, children, closeModal }: ModalProps) {
  if (!isOpen) return null

  return createPortal(
    <StyledModal>
      <ModalWrapper>
        <CloseButton onClick={closeModal}>X</CloseButton>
        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </StyledModal>,
    document.getElementById("react-modal")!,
  )
}

export default Modal

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 997;
  backdrop-filter: blur(3px);
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 850px;
  color: ${({ theme }) => theme.colors.modalCommonText};
  background-color: ${({ theme }) => theme.colors.modalBg};
  border-radius: 20px;
  box-shadow: ${(props) =>
    `8rem 8rem 15rem 1rem ${props.theme.colors.modalShadow}`};
  z-index: 998;
`

const CloseButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 9999px;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  z-index: 999;
`

const ModalContent = styled.div``
