import { AiOutlineLoading } from "react-icons/ai"
import styled, { keyframes } from "styled-components"

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningIcon = styled(AiOutlineLoading)`
  animation: ${spin} 1s linear infinite;
`

const PushButtonContainer = styled.button<{ $isFullWidth?: boolean }>`
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  width: ${(props) => (props.$isFullWidth ? "100%" : "auto")};

  &:hover {
    filter: brightness(110%);

    .front {
      transform: translateY(-6px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .shadow {
      transform: translateY(4px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }
  }

  &:active {
    .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`

const Shadow = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
`

const Edge = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.btnPushEdge};
`

const Front = styled.span<{ height?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  min-height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.btnPushText};
  background: ${({ theme }) => theme.colors.btnPushBg};
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  user-select: none;
`

const IconContainer = styled.span`
  line-height: 0;
  color: ${({ theme }) => theme.colors.btnPushText};
  font-size: 25px;
  margin-right: 8px;
`

interface PushButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode
  isFullWidth?: boolean
  height?: number
  isLoading?: boolean
}

const PushButton = ({ children, icon, isLoading, isFullWidth, height, ...props }: PushButtonProps) => {
  return (
    <PushButtonContainer $isFullWidth={isFullWidth} {...props}>
      <Shadow className="shadow" />
      <Edge className="edge" />
      <Front className="front" height={height}>
        {icon && <IconContainer>{icon}</IconContainer>}
        {isLoading ? <SpinningIcon /> : children}
      </Front>
    </PushButtonContainer>
  )
}

export default PushButton
