import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col justify-center items-center mt-16 space-y-3">
      <p>Không tìm thấy nội dung phù hợp</p>
      <Button onClick={() => navigate("/")}>Về trang chủ</Button>
    </div>
  )
}

export default NotFound
