interface SettingBoxProps {
  titleIcon?: React.ReactNode
  title: string
  children: React.ReactNode
}

const SettingBox = ({ title, titleIcon, children }: SettingBoxProps) => {
  return (
    <div className="rounded-sm p-5 border-border bg-background-subdued">
    <h2 className="flex items-center pb-4 border-b border-b-border">
        {titleIcon && <span className="mr-2.5 text-2xl">{titleIcon}</span>}
        <span className="text-lg font-bold">{title}</span>
    </h2>
    <div className="pt-5">{children}</div>
    </div>
  )
}


export {SettingBox}