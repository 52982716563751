import { useTranslation } from "react-i18next"
import Modal from "../../component/Modal"
import Modal2 from "../../component/Modal2"
import { FORM_TYPE, FORM_TYPES } from "../../constants/FormTypes"
import useAuthFormStore from "../../store/authFormStore"
import { useDarkModeStore } from "../../store/useDarkModeStore"
import AuthForm from "../AuthForm"
import { useAccessToken } from "../../store/authStore"
import { UserStatusBar } from "./user-status-bar"
import { Toggle } from "@/components/ui/toggle"
import { MdOutlineLightMode } from "react-icons/md"
import { Button } from "@/components/ui/button"
import { useCallback, useLayoutEffect } from "react"
import { SearchDialog } from "@/modules/search/search-dialog"
import { useRelayEnvironment } from "react-relay"

const Topbar: React.FC = () => {
  const toggleDarkMode = useDarkModeStore((state) => state.toggleThemeMode)
  const isDarkMode = useDarkModeStore((state) => state.isDarkMode)
  const { setFormType } = useAuthFormStore((state) => state)
  const isAuthenticated = useAccessToken()
  const { isOpenAuthForm, closeAuthForm, openAuthForm } = useAuthFormStore()
  const { t } = useTranslation()

  const handleOpenForm = (kind: FORM_TYPE) => () => {
    setFormType(kind)
    openAuthForm()
  }

  const handleThemeChange = useCallback(() => {
    document.documentElement.classList.toggle("dark", !isDarkMode)
    toggleDarkMode()
  }, [isDarkMode, toggleDarkMode])

  useLayoutEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const relayEnvironment = useRelayEnvironment()
  const handleGetRelaySource = () => {
    const store = relayEnvironment.getStore()

    const data = store.getSource().toJSON()
    localStorage.setItem("RELAY_STORE_KEY", JSON.stringify(data))
  }

  return (
    <nav className="flex flex-shrink-0 items-center justify-between bg-background py-2 shadow-sm dark:bg-background-subdued lg:py-5">
      <div className="flex flex-1 justify-between px-7">
        <button className="hidden" onClick={handleGetRelaySource}>
          Get relay source
        </button>
        <div className="hidden md:block">
          <SearchDialog />
        </div>
        <div className="ml-auto flex items-center gap-2">
          <Toggle
            aria-label="Toggle dark mode"
            className="text-on-surface-subdued hover:bg-transparent"
            onClick={() => handleThemeChange()}>
            <MdOutlineLightMode className="size-8" />
          </Toggle>
        </div>
      </div>
      <div className="w-74 relative hidden gap-2 px-3 pl-5 md:flex">
        {isAuthenticated ? (
          <UserStatusBar />
        ) : (
          <div className="mx-3 space-x-2">
            <Button onClick={handleOpenForm(FORM_TYPES.LOGIN)}>{t("Sign in")}</Button>
            <Button onClick={handleOpenForm(FORM_TYPES.REGISTER)}>{t("Sign up")}</Button>
          </div>
        )}
      </div>
      <Modal isOpen={isOpenAuthForm} closeModal={closeAuthForm}>
        <AuthForm />
      </Modal>
      <Modal2 />
    </nav>
  )
}

export { Topbar }
