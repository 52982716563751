import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { type useUserQuery } from "./__generated__/useUserQuery.graphql"
import { QueryOptions } from "@/types/QueryOptions"

const query = graphql`
  query useUserQuery {
    users {
      id
      email
      role {
        name
      }
      firstName
      lastName
      createdAt
      lastSeen
      deletedAt
    }
  }
`

const useUserQuery = ({ options }: { options?: QueryOptions } = {}) => {
  const { users } = useLazyLoadQuery<useUserQuery>(query, {}, { ...options })

  return users
}

export { useUserQuery }
