import { Component, MouseEvent, useEffect, useState } from "react"
import { BiPlayCircle } from "react-icons/bi"
import { cn } from "@/utils/utils"
import { Button } from "@/components/ui/button"
import { BsBookmarkHeart, BsBookmarkHeartFill } from "react-icons/bs"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { toast } from "@/components/ui/use-toast"
import { useBookmarkMutation } from "@/pages/Bookmark/services/useBookmarkMutation"
import { ConnectionHandler } from "relay-runtime"
import { useAccessToken } from "@/store/authStore"
import { useTranslation } from "react-i18next"
import { FlashcardType } from "@/pages/Dashboard/flashcard-area"

const getImageLink = (image: string) =>
  `https://firebasestorage.googleapis.com/v0/b/dotvocabs.appspot.com/o/images%2F${image}?alt=media`

const getAudioLink = (audio: string) =>
  `https://firebasestorage.googleapis.com/v0/b/dotvocabs.appspot.com/o/audios%2F${audio}?alt=media`

type FlashcardProps = FlashcardType &
  React.ComponentProps<typeof Component> & {
    isBookmarked: boolean
    onMarkAsRemembered: (id: string) => void
    isMarkingFLashcard: boolean
    isRemembered: boolean
  }

const isTextSelected = () => {
  return window.getSelection()?.toString().length ?? 0 > 0
}
const Flashcard = ({ node, isBookmarked, onMarkAsRemembered, isMarkingFLashcard, isRemembered }: FlashcardProps) => {
  const { t } = useTranslation()
  const [isFlipped, setIsFlipped] = useState(false)
  const [bookmark] = useBookmarkMutation()
  const isAuthenticated = useAccessToken()
  const { id, word } = node

  const handleCardClick = () => {
    if (!isTextSelected()) {
      setIsFlipped(!isFlipped)
    }
  }

  // Handler for key press event (flipping on Spacebar press)
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const activeElement = document.activeElement
      const isInputActive = activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement

      if (event.code === "Space" && !isInputActive) {
        event.preventDefault()
        handleCardClick()
      }
    }

    // Add event listener for keypress
    window.addEventListener("keydown", handleKeyPress)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlipped])

  const handleSoundPlay = (e: React.MouseEvent<HTMLElement>) => {
    if (node.__typename !== "Flashcard") return
    e.stopPropagation()
    const audioPlayer = new Audio(getAudioLink(node.audio))
    audioPlayer.play()
  }

  const PhoneticSound = () => {
    return (
      node.__typename === "Flashcard" && (
        <div className="flex items-center justify-center gap-2">
          <p className="text-center text-lg">{node.phonetic}</p>
          <button
            onClick={handleSoundPlay}
            className="cursor-pointer rounded-full border-none p-2 text-2xl active:opacity-80">
            <BiPlayCircle />
          </button>
        </div>
      )
    )
  }

  const handleBookmark = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation()

    if (!isAuthenticated) {
      return toast({
        title: t("common.message.Notification"),
        description: t("common.message.You need to login to use"),
      })
    }

    const connectionID = ConnectionHandler.getConnectionID("client:root", "zb_getUserBookmarks")

    bookmark({
      variables: {
        input: {
          flashcard: id,
        },
        connections: [connectionID],
      },
      onCompleted() {
        toast({
          title: t("common.message.Notification"),
          description: t("common.message.Save successfully"),
        })
      },
    })
  }

  const handleMarkAsRemembered = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation()
    onMarkAsRemembered(id)
  }

  return (
    <div
      onClick={handleCardClick}
      className="mx-auto mb-5 max-w-5xl cursor-pointer overflow-auto rounded-lg bg-background-subdued p-3 shadow-xl md:mb-10 md:p-10 lg:h-[380px] 2xl:h-[500px]">
      <div className="relative grid h-full lg:grid-cols-3">
        {node.__typename === "Flashcard" && (
          <figure className="mx-auto my-auto h-52 overflow-hidden rounded">
            <img src={getImageLink(node.image)} alt="" className="h-full w-full object-cover" />
          </figure>
        )}
        <div
          className={cn(
            "duration-400 relative mt-0 h-full w-full flex-1 transition-transform transform-style-preserve-3d perspective-1000 md:mt-0 lg:col-span-3",
            isFlipped && "[transform:rotateY(180deg)]",
            node.__typename === "Flashcard" && "lg:col-span-2",
          )}>
          <div className="relative left-0 top-0 flex h-full w-full flex-col justify-center p-3 backface-hidden md:p-10">
            <p className="text-center text-3xl font-bold sm:text-4xl">{word}</p>
            {node.__typename === "Flashcard" && (
              <p className="mt-2 text-center text-xl sm:text-2xl">{node.partOfSpeech}</p>
            )}
            <PhoneticSound />
            {node.__typename === "Flashcard" &&
              node.sentences.map((sentence) => (
                <p key={sentence.text} className="mt-15 text-center text-xl md:text-2xl">
                  {sentence.text}
                </p>
              ))}
          </div>
          <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-center p-3 backface-hidden [transform:rotateY(180deg)] md:p-10">
            <p className="text-center text-2xl font-bold">
              {node.__typename === "Flashcard"
                ? node.translations?.vi || <i>Not available</i>
                : node.content || <i>Not available</i>}
            </p>
            {node.__typename === "Flashcard" && <p className="mt-2 text-center text-lg">{node.partOfSpeech}</p>}
            <PhoneticSound />

            {node.__typename === "Flashcard" &&
              node.sentences.map((sentence) => (
                <p key={sentence.text} className="mt-15 text-center text-lg">
                  {sentence.translations?.vi}
                </p>
              ))}
          </div>
        </div>
        {node.__typename === "Flashcard" && (
          <div className="absolute right-0 top-0 flex items-center gap-2">
            {isAuthenticated && !isRemembered && node.__typename === "Flashcard" && (
              <Button isLoading={isMarkingFLashcard} onClick={(e) => handleMarkAsRemembered(e, id)}>
                {t("flashcard.markAsRemembered")}
              </Button>
            )}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="text-2xl text-on-surface-subdued"
                    disabled={isBookmarked}
                    onClick={(e) => handleBookmark(e, id)}>
                    {isBookmarked ? <BsBookmarkHeartFill /> : <BsBookmarkHeart />}
                    <span className="ml-2 text-sm">{t("flashcard.save")}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Bookmark this word</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    </div>
  )
}

export { Flashcard }
