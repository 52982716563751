import { Suspense } from "react"
import Loading from "../../layout/Loading"
import { Virtuoso } from "react-virtuoso"
import { useUserQuery } from "./services/useUserQuery"
import { useMutation, graphql } from "react-relay"
import { useState } from "react"
import { pageUser_DeleteUserMutation } from "@/pages/User/__generated__/pageUser_DeleteUserMutation.graphql"
import { Button } from "@/components/ui/button"
import { PiTrashLight } from "react-icons/pi"
import { BiRefresh } from "react-icons/bi"
import { QueryOptions } from "@/types/QueryOptions"

const deleteUserMutation = graphql`
  mutation pageUser_DeleteUserMutation($id: String!) {
    deleteUser(id: $id) {
      id
      deletedAt
    }
  }
`

const UserPage = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<QueryOptions>({})
  const users = useUserQuery({ options: refreshedQueryOptions })
  const [commitDeleteUser] = useMutation<pageUser_DeleteUserMutation>(deleteUserMutation)
  const [userToDelete, setUserToDelete] = useState<string | null>(null)
  const [confirmationInput, setConfirmationInput] = useState("")

  const handleRefresh = () => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: Number(prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }))
  }

  const handleDeleteUser = (userId: string) => {
    setUserToDelete(userId)
    setConfirmationInput("")
  }

  const confirmDeleteUser = () => {
    if (!userToDelete || confirmationInput !== userToDelete) return

    commitDeleteUser({
      variables: { id: userToDelete },
      onCompleted: (response, errors) => {
        if (errors) {
          console.error("Error deleting user:", errors)
          // Handle error (e.g., show an error message to the user)
        } else {
          console.log("User deleted successfully: ", response)
          // Optionally, update the local store or refetch the users list
        }
        setUserToDelete(null)
        setConfirmationInput("")
      },
      onError: (error) => {
        console.error("Error deleting user:", error)
        // Handle error (e.g., show an error message to the user)
        setUserToDelete(null)
        setConfirmationInput("")
      },
    })
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="h-full p-10">
        <div className="mb-4 flex justify-end">
          <Button variant="outline" onClick={handleRefresh} className="flex items-center gap-2">
            <BiRefresh className="h-4 w-4" />
            Refresh
          </Button>
        </div>
        {userToDelete && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4">Are you sure you want to delete this user?</p>
              <p className="mb-2">Please enter the user ID to confirm:</p>
              <input
                type="text"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
                className="mb-4 w-full rounded border p-2"
                placeholder="Enter user ID"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => {
                    setUserToDelete(null)
                    setConfirmationInput("")
                  }}
                  className="rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400">
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteUser}
                  disabled={confirmationInput !== userToDelete}
                  className={`rounded px-4 py-2 font-bold text-white ${
                    confirmationInput === userToDelete ? "bg-red-500 hover:bg-red-700" : "cursor-not-allowed bg-red-300"
                  }`}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        <Suspense fallback={<Loading />}>
          <Virtuoso
            totalCount={users.length}
            data={users}
            itemContent={(idx, user) => (
              <div
                key={user.id}
                className="flex space-x-5 border-b px-4 py-2 text-on-surface-subdued hover:cursor-pointer hover:bg-background-subdued">
                <p className="w-10 truncate">{idx + 1}</p>
                <p className="w-20 truncate">{user.id}</p>
                <p className="min-w-40 truncate">{user.firstName + " " + user.lastName}</p>
                <p className="w-20">{user.role.name}</p>
                <p className="w-96">{user.email}</p>
                <p className="flex-1">{new Date(Number(user.createdAt)).toLocaleString()}</p>
                <p>{user.lastSeen ? new Date(Number(user.lastSeen)).toLocaleString() : "N/A"} </p>
                <div className="w-10">
                  {user.role.name !== "Admin" && (
                    <Button variant="outline" onClick={() => handleDeleteUser(user.id)}>
                      <PiTrashLight className="text-red-400" />
                    </Button>
                  )}
                </div>
              </div>
            )}
          />
        </Suspense>
      </div>
    </Suspense>
  )
}
export { UserPage }
