/**
 * @generated SignedSource<<561709c46af69d9b64d0825775203d9c>>
 * @relayHash ab0eed74f31862124b7799855f3bac2c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab0eed74f31862124b7799855f3bac2c

import { ConcreteRequest, Query } from 'relay-runtime';
export type useVocabsQuery$variables = Record<PropertyKey, never>;
export type useVocabsQuery$data = {
  readonly flashcards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly image: string;
        readonly translations: {
          readonly vi: string | null;
        };
        readonly word: string;
      };
    }>;
  };
};
export type useVocabsQuery = {
  response: useVocabsQuery$data;
  variables: useVocabsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 300
      }
    ],
    "concreteType": "FlashcardsConnection",
    "kind": "LinkedField",
    "name": "flashcards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FlashcardsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flashcard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "word",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FlashcardTranslations",
                "kind": "LinkedField",
                "name": "translations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vi",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "flashcards(first:300)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useVocabsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useVocabsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "ab0eed74f31862124b7799855f3bac2c",
    "metadata": {},
    "name": "useVocabsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "34570c7132c7ce6be8028c134c206029";

export default node;
