/**
 * @generated SignedSource<<5686955192b1f0f3c0fe3dad0b579658>>
 * @relayHash e57788fae09958d90f7a6b8ace92db7b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e57788fae09958d90f7a6b8ace92db7b

import { ConcreteRequest, Query } from 'relay-runtime';
export type searchQuery$variables = {
  sourceLang: string;
  targetLang: string;
  text: string;
};
export type searchQuery$data = {
  readonly search: {
    readonly confidence: number;
    readonly definitions: ReadonlyArray<{
      readonly base_form: string;
      readonly entry: ReadonlyArray<{
        readonly definition_id: string;
        readonly example: string | null;
        readonly gloss: string;
        readonly label_info: {
          readonly register: ReadonlyArray<string> | null;
          readonly subject: ReadonlyArray<string> | null;
        } | null;
      }>;
      readonly pos: string;
      readonly pos_enum: number;
    }> | null;
    readonly dict: ReadonlyArray<{
      readonly base_form: string;
      readonly entry: ReadonlyArray<{
        readonly reverse_translation: ReadonlyArray<string>;
        readonly word: string;
      }>;
      readonly pos: string;
      readonly pos_enum: number;
      readonly terms: ReadonlyArray<string>;
    }> | null;
    readonly examples: {
      readonly example: ReadonlyArray<{
        readonly definition_id: string;
        readonly text: string;
      }>;
    } | null;
    readonly ld_result: {
      readonly extended_srclangs: ReadonlyArray<string>;
      readonly srclangs: ReadonlyArray<string>;
      readonly srclangs_confidences: ReadonlyArray<number>;
    } | null;
    readonly sentences: ReadonlyArray<{
      readonly backend: number | null;
      readonly orig: string | null;
      readonly src_translit: string | null;
      readonly trans: string | null;
    }> | null;
    readonly src: string;
    readonly synsets: ReadonlyArray<{
      readonly base_form: string;
      readonly entry: ReadonlyArray<{
        readonly definition: string | null;
        readonly definition_id: string;
        readonly label_info: {
          readonly register: ReadonlyArray<string> | null;
          readonly subject: ReadonlyArray<string> | null;
        } | null;
        readonly synonym: ReadonlyArray<string>;
      }>;
      readonly pos: string;
      readonly pos_enum: number;
    }> | null;
  };
};
export type searchQuery = {
  response: searchQuery$data;
  variables: searchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetLang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pos",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "base_form",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pos_enum",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definition_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "LabelInfo",
  "kind": "LinkedField",
  "name": "label_info",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "register",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "sourceLang",
        "variableName": "sourceLang"
      },
      {
        "kind": "Variable",
        "name": "targetLang",
        "variableName": "targetLang"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "Search",
    "kind": "LinkedField",
    "name": "search",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "src",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confidence",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Sentence",
        "kind": "LinkedField",
        "name": "sentences",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trans",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backend",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "src_translit",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dict",
        "kind": "LinkedField",
        "name": "dict",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Entry",
            "kind": "LinkedField",
            "name": "entry",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "word",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reverse_translation",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Synset",
        "kind": "LinkedField",
        "name": "synsets",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SynsetEntry",
            "kind": "LinkedField",
            "name": "entry",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "synonym",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "definition",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Definition",
        "kind": "LinkedField",
        "name": "definitions",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DefinitionEntry",
            "kind": "LinkedField",
            "name": "entry",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gloss",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "example",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Examples",
        "kind": "LinkedField",
        "name": "examples",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExampleText",
            "kind": "LinkedField",
            "name": "example",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LDResult",
        "kind": "LinkedField",
        "name": "ld_result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "srclangs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "srclangs_confidences",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extended_srclangs",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "id": "e57788fae09958d90f7a6b8ace92db7b",
    "metadata": {},
    "name": "searchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "28b84cc3daadce9608abd235cfda358d";

export default node;
