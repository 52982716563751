/**
 * @generated SignedSource<<0b02ff8b7bbb8a040b4f2838c643d020>>
 * @relayHash 1b574b1414c6407e3aefea387db3c60a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1b574b1414c6407e3aefea387db3c60a

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppLanguage = "English" | "Vietnamse";
export type LearningLanguage = "chinese" | "english" | "japanese" | "vietnamese";
export type RoleName = "Admin" | "Manager" | "Teacher" | "User";
export type ThemeMode = "Dark" | "Light";
export type UpdateUserSettingsInput = {
  appLanguage?: AppLanguage | null;
  chosenTopics?: ReadonlyArray<string> | null;
  learningLanguage?: LearningLanguage | null;
  themeMode?: ThemeMode | null;
};
export type useUpdateUserSettingMutation$variables = {
  input: UpdateUserSettingsInput;
};
export type useUpdateUserSettingMutation$data = {
  readonly updateUserSettings: {
    readonly active: boolean;
    readonly address: string | null;
    readonly appLanguage: AppLanguage | null;
    readonly chosenTopics: ReadonlyArray<string | null> | null;
    readonly createdAt: string;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly ip: string | null;
    readonly lastName: string;
    readonly learningLanguage: LearningLanguage | null;
    readonly online: boolean;
    readonly role: {
      readonly name: RoleName;
    };
    readonly themeMode: ThemeMode | null;
    readonly updatedAt: string;
  };
};
export type useUpdateUserSettingMutation = {
  response: useUpdateUserSettingMutation$data;
  variables: useUpdateUserSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updateUserSettingsInput",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUserSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "online",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "themeMode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appLanguage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "learningLanguage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chosenTopics",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateUserSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateUserSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "1b574b1414c6407e3aefea387db3c60a",
    "metadata": {},
    "name": "useUpdateUserSettingMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d4af244890b4a66b0c91e5a37e68e8eb";

export default node;
