/**
 * @generated SignedSource<<edd52db86810828ca8950a544e225bf5>>
 * @relayHash d66219fc70c8c325e8b1aec1ba1a17ca
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d66219fc70c8c325e8b1aec1ba1a17ca

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserFlashcardInput = {
  flashcardId: string;
  isRemembered: boolean;
};
export type useMarkFlashcardAsRememberedMutation$variables = {
  input: UserFlashcardInput;
};
export type useMarkFlashcardAsRememberedMutation$data = {
  readonly markFlashcardAsRemembered: {
    readonly createdAt: string;
    readonly flashcard: string;
    readonly id: string;
    readonly isRemembered: boolean;
    readonly rememberedAt: string | null;
    readonly updatedAt: string;
    readonly user: string;
  };
};
export type useMarkFlashcardAsRememberedMutation = {
  response: useMarkFlashcardAsRememberedMutation$data;
  variables: useMarkFlashcardAsRememberedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserFlashcard",
    "kind": "LinkedField",
    "name": "markFlashcardAsRemembered",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flashcard",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRemembered",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rememberedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMarkFlashcardAsRememberedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMarkFlashcardAsRememberedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d66219fc70c8c325e8b1aec1ba1a17ca",
    "metadata": {},
    "name": "useMarkFlashcardAsRememberedMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "081e28e676ba630137c1309450fedf18";

export default node;
