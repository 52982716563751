import { graphql, useMutation } from "react-relay"
import { useBookmarkMutation as useBookmarkMutationProps } from "./__generated__/useBookmarkMutation.graphql"

const mutation = graphql`
  mutation useBookmarkMutation($input: CreateBookmarkInput!, $connections: [ID!]!) {
    createBookmark(createBookmarkInput: $input) @prependNode(connections: $connections, edgeTypeName: "BookmarksEdge") {
      id
      word
      sourceType
      flashcard {
        word
        translations {
          vi
        }
      }
    }
  }
`

export const useBookmarkMutation = () => {
  return useMutation<useBookmarkMutationProps>(mutation)
}
