const About = () => {
  return (
    <div className="flex justify-center items-center mt-3">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSe-t6F6E6UM91Fgk1DTw2UvGIhYDV-JFN3JD-SYEm4RJHZwRg/viewform?embedded=true"
        width="640"
        height="760">
        Đang tải…
      </iframe>
    </div>
  )
}

export  {About}
