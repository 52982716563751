import { styled } from "styled-components"
import { useDropzone } from "react-dropzone"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

import Select from "react-select"
import { ReactSelect } from "./reproduce-react-select"
import { Home, Plus } from "lucide-react"
import DateMaskInput from "./mask"
import { useState } from "react"
import { useRecipeSubscription } from "@/pages/Poc/services/useRecipeSubscription"
import { useCreateRecipeMutation } from "@/pages/Poc/services/useCreateRecipeMutation"
import { Button } from "@/components/ui/button"
import { FilterableSelect } from "@/components/filterable-select"

// const UPLOAD_FILE_MUTATION = graphql`
//   mutation FileUploadMutation($file: Upload!) {
//     uploadFile(file: $file)
//   }
// `

const Schema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  age: z.string().regex(/^\d+$/).transform(Number),
})

type SchemaType = z.infer<typeof Schema>

type Option = {
  id: string
  name: string
}

const ValidationPractice = () => {
  const form = useForm<SchemaType>({
    resolver: zodResolver(Schema),
    defaultValues: {
      age: undefined,
    },
  })

  try {
    const parsed = Schema.parse({
      name: "abc",
      age: 2,
    })

    console.log("@parsed: ", parsed)
  } catch (error) {
    // console.log("@error: ", error)
  }

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = form

  const onSubmit: SubmitHandler<SchemaType> = (data) => {
    console.log(data)
  }

  useRecipeSubscription()

  return (
    <div>
      <FormProvider {...form}>
        <fieldset>
          <input placeholder="Enter name" autoComplete="off" {...register("name")} />
          <p style={{ fontSize: "14px" }}>{errors.name?.message}</p>
        </fieldset>
        <fieldset>
          <input placeholder="Enter age" autoComplete="off" {...register("age")} />
          <p style={{ fontSize: "14px" }}>{errors.age?.message?.toString()}</p>
        </fieldset>
        <button onClick={handleSubmit(onSubmit)}>Submit</button>
      </FormProvider>
    </div>
  )
}

const Poc = () => {
  // const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION)
  const [createRecipe] = useCreateRecipeMutation()

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    try {
      console.log(file)
      // await uploadFile({
      //   variables: {
      //     file,
      //   },
      // })
      console.log("File uploaded successfully!")
    } catch (error) {
      console.error("Error uploading file:", error)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const dogOptions = [
    { value: 1, name: "Chihuahua", icon: Plus, disabled: true },
    { value: 2, name: "Bulldog", icon: Home },
    { value: 3, name: "Dachshund", icon: Plus },
    { value: 4, name: "Akita", icon: Plus },
  ]

  const [date, setDate] = useState<string>("")

  const groups = [
    {
      heading: "",
      options: [
        {
          id: "1",
          name: "Option abcabc abc abc abc abc abc abc abc abc abc  1",
        },
        {
          id: "2",
          name: "Option 2",
        },
      ],
    },
    {
      heading: "Group",
      options: [
        {
          id: "3",
          name: "Option 3",
        },
        {
          id: "4",
          name: "Option 4",
        },
      ],
    },
  ]

  // const options = [
  //   {
  //     id: "1",
  //     name: "Option abcabc abc abc abc abc abc abc abc abc abc  1",
  //   },
  //   {
  //     id: "2",
  //     name: "Option 2",
  //   },
  // ]

  return (
    <Wrapper>
      <div>
        <FilterableSelect<Option>
          groups={groups}
          clearable
          // value={objValue ? objValue : undefined}
          data-testid="accident-insurance"
          // error={error?.message}
          getOptionLabel={(option) => option.name}
          formatOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option.id}
          isOptionSelected={(option, selectedValue) => option.id === selectedValue.id}
          // onChange={(value) => onChange(value?.id)}
        />

        <Button
          onClick={() => {
            createRecipe({
              variables: {
                input: {
                  title: "This is a demo",
                  description: "Desc",
                  ingredients: ["ingredient1"],
                },
              },
            })
          }}>
          Create recipe
        </Button>
        <div className="mt-10">
          <DateMaskInput value={date} onChange={setDate} />

          {/* Multiple select */}
          <ReactSelect
            value={[{ value: 4, name: "Akita", icon: Plus }]}
            options={dogOptions}
            getOptionLabel={(option) => {
              const IconComponent = option?.icon
              return (
                <div className="flex cursor-pointer space-x-2 rounded-lg bg-slate-500 p-2 hover:opacity-65">
                  <IconComponent /> <span>{option.name}</span>
                </div>
              )
            }}
            onChange={(value) => {
              console.log("@onChange: ", value)
            }}
            isOptionDisabled={(option) => !!option.disabled}
            // getOptionValue={}
          />
        </div>
        <div className="mt-10">
          {/* react-select */}
          <Select
            isMulti
            options={dogOptions}
            getOptionLabel={(v) => v.name}
            menuIsOpen
            onChange={(newValue) => {
              console.log("newValue: ", newValue)
            }}
          />
        </div>
        <ValidationPractice />
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed #cccccc",
            borderRadius: "4px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
          }}>
          <input {...getInputProps()} />
          <p>Drag & drop a file here, or click to select one</p>
        </div>
        <div className="mt-10">
          <div className="dog-container">
            <div className="dog">
              <div className="dog-body">
                <div className="dog-tail">
                  <div className="dog-tail">
                    <div className="dog-tail">
                      <div className="dog-tail">
                        <div className="dog-tail">
                          <div className="dog-tail">
                            <div className="dog-tail">
                              <div className="dog-tail"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dog-torso"></div>
              <div className="dog-head">
                <div className="dog-ears">
                  <div className="dog-ear"></div>
                  <div className="dog-ear"></div>
                </div>
                <div className="dog-eyes">
                  <div className="dog-eye"></div>
                  <div className="dog-eye"></div>
                </div>
                <div className="dog-muzzle">
                  <div className="dog-tongue"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`

export default Poc
