import { forwardRef } from "react"
import styled from "styled-components"

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMsg?: string
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(({ errorMsg, ...rest }, ref) => {
  return (
    <>
      <InputWrapper {...rest} ref={ref} />
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
    </>
  )
})
export default Input

const InputWrapper = styled.input`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  background: ${({ theme }) => theme.colors.inputBg};
  padding: 15px 13px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.colors.inputText};
  outline-offset: 3px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.btnPrimaryBg};
  }
`

const ErrorMessage = styled.p`
  margin-top: 5px;
  color: #ec5555;
`
