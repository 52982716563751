import React, { ButtonHTMLAttributes } from "react"
import styled, { css } from "styled-components"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  icon?: React.ReactNode
  variant?: "primary" | "outline" // Specify valid variants
}

const Button = ({ onClick, icon, children, variant = "primary", ...props }: ButtonProps) => {
  return (
    <ButtonWrap $variant={variant} onClick={onClick} {...props}>
      {icon && <span>{icon}</span>}
      {children}
    </ButtonWrap>
  )
}

interface ButtonWrapProps {
  $variant: "primary" | "outline"
  disabled?: boolean
}

export const ButtonWrap = styled.button<ButtonWrapProps>`
  display: flex;
  min-width: 127px;
  padding: 0 10px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) => props.disabled && "transparent!important"};

  /* Primary variant styles */
  ${(props) =>
    props.$variant === "primary" &&
    css`
      background: ${({ theme }) => theme.colors.btnPrimaryBg};
      color: ${({ theme }) => theme.colors.btnText};
      border: 2px solid transparent;

      &:hover {
        background: transparent;
        color: ${props.theme.colors.btnHoverText};
        border-color: ${props.theme.colors.btnBorder};
      }
    `}

  /* Outline variant styles */
      ${(props) =>
    props.$variant === "outline" &&
    css`
      background: none;
      color: ${props.theme.colors.btnOutlineText};
      border: 2px solid ${props.theme.colors.btnOutlineLine};

      &:hover {
        background-color: ${props.theme.colors.btnPrimaryBg};
        border-color: transparent;
        color: ${props.theme.colors.btnOutlineHoverText};
      }
    `}
`

export default Button
