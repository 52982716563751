import { Dialog } from "@headlessui/react"
import { useModalStore } from "../../store/modalStore"
import { createPortal } from "react-dom"
import { styled } from "styled-components"
import Button from "../Button"

const StyledDialog = styled(Dialog)`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 999;
`

const DialogPanel = styled(Dialog.Panel)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.modal2Bg};
  border-radius: 12px;
  padding: 28px;
  max-width: 400px;
  min-height: 200px;
  margin: 120px auto;
  text-align: center;
  box-shadow: ${(props) => `8px 8px 15px 1px ${props.theme.colors.modal2Shadow}`};
`

const DialogTitle = styled(Dialog.Title)`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.modal2Title};
`

const DialogDescription = styled(Dialog.Description)`
  margin-top: 23px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.modal2Desc};
  margin-bottom: 20px;
`

const DialogAction = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 15px;
`

const Modal2 = () => {
  const isOpen = useModalStore((state) => state.isOpen)
  const closeModal = useModalStore((state) => state.closeModal)
  const modalContent = useModalStore((state) => state.modalContent)
  const onConfirm = useModalStore((state) => state.onConfirm)
  const onCancel = useModalStore((state) => state.onCancel)

  const handleConfirm = () => {
    onConfirm()
    closeModal()
  }

  const handleCancel = () => {
    onCancel()
    closeModal()
  }

  if (!isOpen) return null

  return createPortal(
    <StyledDialog open={isOpen} onClose={closeModal}>
      <DialogPanel>
        <DialogTitle>Bạn có chắc chắn?</DialogTitle>
        <DialogDescription as="div">{modalContent}</DialogDescription>
        <DialogAction>
          <Button onClick={handleConfirm}>Chắc chắn</Button>
          <Button variant="outline" onClick={handleCancel}>
            Huỷ bỏ
          </Button>
        </DialogAction>
      </DialogPanel>
    </StyledDialog>,
    document.body,
  )
}

export default Modal2
