/**
 * @generated SignedSource<<8dbdc7ff19e89f642497173974d13481>>
 * @relayHash 938587275b3dce467e86c26f91cde45d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 938587275b3dce467e86c26f91cde45d

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBookmarkInput = {
  flashcard: string;
};
export type useBookmarkMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateBookmarkInput;
};
export type useBookmarkMutation$data = {
  readonly createBookmark: {
    readonly flashcard: {
      readonly translations: {
        readonly vi: string | null;
      };
      readonly word: string;
    } | null;
    readonly id: string;
    readonly sourceType: string;
    readonly word: string | null;
  };
};
export type useBookmarkMutation = {
  response: useBookmarkMutation$data;
  variables: useBookmarkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "createBookmarkInput",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "word",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FlashcardTranslations",
  "kind": "LinkedField",
  "name": "translations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vi",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useBookmarkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Bookmark",
        "kind": "LinkedField",
        "name": "createBookmark",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flashcard",
            "kind": "LinkedField",
            "name": "flashcard",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useBookmarkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Bookmark",
        "kind": "LinkedField",
        "name": "createBookmark",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flashcard",
            "kind": "LinkedField",
            "name": "flashcard",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "prependNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createBookmark",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "BookmarksEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "938587275b3dce467e86c26f91cde45d",
    "metadata": {},
    "name": "useBookmarkMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e2d95ccc1e4df9f9f4f0d47664c20972";

export default node;
