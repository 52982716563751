/**
 * @generated SignedSource<<0d1d1a4cbaf04b3271872be4a24d1f6b>>
 * @relayHash 96f2c21ea38558128e5b0b2f43136a1c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 96f2c21ea38558128e5b0b2f43136a1c

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useRemoveBookmarkMutation$variables = {
  id: string;
};
export type useRemoveBookmarkMutation$data = {
  readonly removeBookmark: {
    readonly flashcard: {
      readonly id: string;
      readonly translations: {
        readonly vi: string | null;
      };
      readonly word: string;
    } | null;
    readonly id: string;
    readonly sourceType: string;
    readonly word: string | null;
  } | null;
};
export type useRemoveBookmarkMutation = {
  response: useRemoveBookmarkMutation$data;
  variables: useRemoveBookmarkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "word",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Bookmark",
    "kind": "LinkedField",
    "name": "removeBookmark",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sourceType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Flashcard",
        "kind": "LinkedField",
        "name": "flashcard",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FlashcardTranslations",
            "kind": "LinkedField",
            "name": "translations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vi",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRemoveBookmarkMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRemoveBookmarkMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "96f2c21ea38558128e5b0b2f43136a1c",
    "metadata": {},
    "name": "useRemoveBookmarkMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1f6eee403710091463168c5d85d9fe95";

export default node;
