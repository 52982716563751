import { useState } from "react"
import { Virtuoso } from "react-virtuoso"
import { useUserBookmarkQuery } from "./services/useUserBookmarkQuery"
import { PiTrashLight } from "react-icons/pi"
import { cn } from "@/utils/utils"
import { useRemoveBookmarkMutation } from "./services/useRemoveBookmarkMutation"
import { toast } from "@/components/ui/use-toast"
import { useTranslation } from "react-i18next"
import { ConnectionHandler } from "relay-runtime"
import { Button } from "@/components/ui/button"
import { QueryOptions } from "@/types/QueryOptions"
import { Badge } from "@/components/ui/badge"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { toString } from "lodash-es"
import { Textarea } from "@/components/ui/textarea"

type ListProps = {
  options: QueryOptions
  searchTerm: string
}

const List = ({ options, searchTerm }: ListProps) => {
  const bookmarks = useUserBookmarkQuery({
    options,
  })
  const [removeBookmark, isRemoving] = useRemoveBookmarkMutation()
  const { t } = useTranslation()
  const [removingIds, setRemovingIds] = useState<string[]>([])

  const [editingId, setEditingId] = useState<string | null>(null);

  
  const handleUpdateBookmark = (id: string, newContent: string) => {
    // Implement the mutation to update the bookmark content
    // For now, we'll just log the change and reset the editing state
    console.log(`Updating bookmark ${id} with new content: ${newContent}`);
    setEditingId(null);
  };

  const handleRemoveBookmark = (id: string) => {
    setRemovingIds((prevIds) => [...prevIds, id])
    removeBookmark({
      variables: {
        id,
      },
      onCompleted() {
        setRemovingIds((prevIds) => prevIds.filter((removingId) => removingId !== id))
        toast({
          title: t("common.message.Notification"),
          description: t("common.message.Remove successfully"),
        })
      },
      updater(store) {
        const root = store.get("client:root")

        if (!root) return null

        const connection = ConnectionHandler.getConnection(root, "zb_getUserBookmarks")

        if (connection) {
          ConnectionHandler.deleteNode(connection, id)
        }
      },
      onError() {
        setRemovingIds((prevIds) => prevIds.filter((removingId) => removingId !== id))
      },
    })
  }

  if (!bookmarks?.edges.length) return null

  const filteredBookmarks =
    bookmarks?.edges.filter((bookmark) => {
      if (!bookmark) return false
      const word =
        bookmark.node.sourceType === "out-app" ? toString(bookmark.node.word) : toString(bookmark.node.flashcard?.word)
      const content =
        bookmark.node.sourceType === "out-app" ? bookmark.node.content : bookmark.node.flashcard?.translations.vi
      return (
        word.toLowerCase().includes(searchTerm.toLowerCase()) ||
        content?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }) || []

  return (
    <>
      <Virtuoso
        className="rounded-md border-2"
        totalCount={bookmarks.edges.length}
        data={filteredBookmarks}
        itemContent={(idx, bookmark) => (
          <div
            key={bookmark.node.id}
            className={cn(
              "space-x-3 border-b px-4 py-6 text-on-surface-subdued hover:cursor-pointer hover:bg-background-subdued",
              "flex",
            )}>
            <p className="w-8 px-2">{idx + 1}</p>
            <p
              className="line-clamp-3 w-3/12 px-4 hover:outline-1 hover:outline-dashed"
              title={
                bookmark.node.sourceType === "out-app"
                  ? toString(bookmark.node.word)
                  : toString(bookmark.node.flashcard?.word)
              }>
              {bookmark.node.sourceType === "out-app" ? bookmark.node.word : bookmark.node.flashcard?.word}
            </p>
            <p className={cn("line-clamp-3 w-4/12 px-4", bookmark.node.sourceType === "out-app" && "hover:outline-1 hover:outline-dashed")}>
              {bookmark.node.sourceType === "out-app" ? (
                editingId === bookmark.node.id ? (
                  <Textarea
                    className="w-full"
                    autoFocus
                    defaultValue={bookmark.node.content ?? ""}
                    onBlur={(e) => handleUpdateBookmark(bookmark.node.id, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleUpdateBookmark(bookmark.node.id, e.currentTarget.value);
                      }
                    }}
                  />
                ) : (
                  <span onClick={() => setEditingId(bookmark.node.id)}>
                    {bookmark.node.content}
                  </span>
                )
              ) : (
                bookmark.node.flashcard?.translations.vi
              )}
            </p>
            {/* <p className="w-48 px-4 opacity-70">{dayjs(bookmark.node.createdAt).format("HH:mm - DD/MM/YYYY")}</p> */}
            <p className="w-48 px-4 opacity-70">{dayjs(bookmark.node.createdAt).format("DD/MM/YYYY")}</p>
            <p className="w-1/12 px-4 text-center">
              {bookmark.node.sourceUrl && (
                <Link target="_blank" rel="noopener noreferrer" className="underline" to={bookmark.node.sourceUrl}>
                  Link
                </Link>
              )}
            </p>
            <div className="w-1/12 px-4">
              <Badge className="text-primary" variant="outline">
                {bookmark.node.sourceType}
              </Badge>
            </div>
            <div className="w-2/12 px-4">
              <Button
                variant="outline"
                isLoading={isRemoving && removingIds.includes(bookmark.node.id)}
                onClick={() => handleRemoveBookmark(bookmark.node.id)}>
                <PiTrashLight />
              </Button>
            </div>
          </div>
        )}
      />
    </>
  )
}

export { List }
