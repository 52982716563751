import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "@/components/ui/use-toast"
import { useReportIssueMutation } from "@/pages/RootBoundary/services/useReportIssueMutation"
import { useTopicQuery } from "@/pages/Setting/services/useTopicQuery"
import { useMeQuery } from "@/services/useMeQuery"
import { useAccessToken } from "@/store/authStore"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FiEdit } from "react-icons/fi"
import { Link } from "react-router-dom"

const InformationSidebar = () => {
  const isAuthenticated = useAccessToken()
  const [reportIssue, isLoading] = useReportIssueMutation()
  const [value, setValue] = useState("")

  const handleSubmit = () => {
    reportIssue({
      variables: {
        input: {
          description: value,
        },
      },
      onCompleted: () => {
        toast({
          title: "Issue reported",
          description: "Thank you for reporting the issue. We will look into it.",
        })
        setValue("")
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        })
      },
    })
  }

  return (
    <div className="hidden w-72 flex-shrink-0 border-l border-border px-5 py-10 xl:block">
      <div className="flex h-full flex-col">
        {isAuthenticated && <CurrentTopicSection />}
        <div className="mt-auto">
          <p className="mb-2 ml-1 text-sm font-bold">Give feedback or report issue:</p>
          <Textarea
            placeholder="What's issue youu found? Or what feedback you want to give us?"
            maxLength={1000}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button isLoading={isLoading} className="mt-2 w-full" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

const CurrentTopicSection = () => {
  const { t } = useTranslation()
  const me = useMeQuery()
  const topics = useTopicQuery()

  const chosenTopics = me?.chosenTopics ?? []
  const filteredTopics = topics.filter((topic) => chosenTopics.includes(topic.id))

  return (
    <div>
      <p className="flex items-center text-lg font-bold">
        {t("Current topic")}:
        <Button
          title="Thay đổi chủ đề"
          asChild
          className="w-7.5 h-7.5 ml-3.75 bg-iconBg text-icon hover:bg-iconBgHover hover:border-iconBgLineHover cursor-pointer rounded-full border-transparent p-2 text-lg transition-all">
          <Link to="/setting">
            <FiEdit />
          </Link>
        </Button>
      </p>
      <div className="mt-2.5">
        <ul className="list-disc overflow-hidden">
          {filteredTopics?.map((topic) => {
            return (
              <li key={topic.id} className="list-inside truncate">
                {topic.name}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export { InformationSidebar }
