import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { QueryOptions } from "@/types/QueryOptions"
import { type useRememberedUserFlashcardQuery } from "@/pages/Statistic/hooks/__generated__/useRememberedUserFlashcardQuery.graphql"

const query = graphql`
  query useRememberedUserFlashcardQuery($skip: Boolean!) {
    getAllRememberedUserFlashcards @skip(if: $skip) {
      id
      rememberedAt
      flashcard
      createdAt
    }
  }
`

const useRememberedUserFlashcardQuery = (
  { options, skip }: { options?: QueryOptions; skip: boolean } = { skip: false },
) => {
  const { getAllRememberedUserFlashcards } = useLazyLoadQuery<useRememberedUserFlashcardQuery>(
    query,
    {
      skip,
    },
    { ...options },
  )

  return getAllRememberedUserFlashcards
}

export { useRememberedUserFlashcardQuery }
