import { styled } from "styled-components"

interface LabelProps extends React.LabelHTMLAttributes<"label"> {
  name: string
}

const Label = ({ name }: LabelProps) => {
  return <LabelWrapper>{name}</LabelWrapper>
}

const LabelWrapper = styled.label`
  display: inline-block;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.labelText};
  font-size: 14px;
`

export default Label
