import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { type useRandomFlashcardQuery } from "./__generated__/useRandomFlashcardQuery.graphql"

export const randomFlashcardQuery = graphql`
  query useRandomFlashcardQuery($skip: Boolean!) {
    randomFlashcard(first: 50) @skip(if: $skip) {
      edges {
        node {
          id
          word
          savedCount
          translations {
            vi
          }
          image
          audio
          phonetic
          partOfSpeech
          sentences {
            text
            translations {
              vi
            }
          }
          __typename
        }
      }
    }
  }
`

const useRandomFlashcardQuery = ({ skip }: { skip: boolean }) => {
  const data = useLazyLoadQuery<useRandomFlashcardQuery>(randomFlashcardQuery, {
    skip,
  })

  return data
}

export { useRandomFlashcardQuery }
