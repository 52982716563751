import { UseMutationConfig, graphql, useMutation } from "react-relay"
import { useCallback } from "react"
import { useSignInRelayMutation as useSignInRelayMutationType } from "./__generated__/useSignInRelayMutation.graphql"

const mutation = graphql`
  mutation useSignInRelayMutation($input: LoginUserInput!) {
    login(loginUserInput: $input) {
      user {
        id
        firstName
        lastName
      }
      token
      refreshToken
    }
  }
`

export const useSignInRelayMutation = () => {
  const [commit, isFlight] = useMutation<useSignInRelayMutationType>(mutation)

  const signUp = useCallback(
    (config: UseMutationConfig<useSignInRelayMutationType>) => {
      commit(config)
    },
    [commit],
  )

  return [signUp, isFlight] as const
}
