import { Suspense } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { useMeQuery$data } from "./services/__generated__/useMeQuery.graphql"
import { getAccessToken } from "./store/authStore"
import Loading from "./layout/Loading"

type UserContextType = {
  me: useMeQuery$data["me"]
}

const AppLoader = () => {
  console.log("AppLoader: ", Date.now().toLocaleString())

  return <Suspense fallback={<Loading />}>{getAccessToken() ? <AppLoaderContent /> : <Outlet />}</Suspense>
}

const AppLoaderContent = () => {
  return <Outlet />
}

const useUser = () => {
  return useOutletContext<UserContextType>()
}

// eslint-disable-next-line react-refresh/only-export-components
export { AppLoader, useUser }
