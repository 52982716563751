import { cn } from "@/utils/utils";
import { ReactNode, useState } from "react";

interface CategorizedOption<Option> {
  type: 'option';
  data: Option;
  isDisabled: boolean;
  isSelected: boolean;
  label: string | ReactNode;
  value: string;
  index: number;
}

const getOptionLabelBuiltin = <Option,>(option: Option): string =>
  (option as { label?: unknown }).label as string;

const getOptionValueBuiltin = <Option,>(option: Option): string =>
  (option as { value?: unknown }).value as string;

const isOptionSelected = <Option,>(
  props: Props<Option>,
  option: Option,
  selectValue: readonly Option[]
): boolean => {
  if (selectValue.includes(option)) return true;

  const candidate = getOptionValue(props, option);
  return selectValue.some((i) => getOptionValue(props, i) === candidate);
}

const isOptionDisabled = <Option,>(
  props: Props<Option>,
  option: Option,
  selectValue: Option[]
): boolean =>
  typeof props.isOptionDisabled === 'function'
    ? props.isOptionDisabled(option, selectValue)
    : false;

const toCategorizedOption = <Option,>(
  props: Props<Option>,
  option: Option,
  selectValue: Option[],
  index: number
): CategorizedOption<Option> => ({
  type: 'option',
  data: option,
  isDisabled: isOptionDisabled(props, option, selectValue),
  isSelected: isOptionSelected(props, option, selectValue),
  label: getOptionLabel(props, option),
  value: getOptionValue(props, option),
  index,
});

const getOptionLabel = <Option,>(
  props: Props<Option>,
  data: Option
): string | ReactNode =>
  props.getOptionLabel ? props.getOptionLabel(data) : getOptionLabelBuiltin(data);

const getOptionValue = <Option,>(
  props: Props<Option>,
  data: Option
): string =>
  props.getOptionValue ? props.getOptionValue(data) : getOptionValueBuiltin(data);

const buildOptions = <Option,>(props: Props<Option>, selectValue: Option[]) =>
  props.options.map((option, idx) => toCategorizedOption(props, option, selectValue, idx));

interface Props<Option> {
  value?: readonly Option[];
  options: Option[];
  onChange?: (newValue: Option[]) => void;
  getOptionLabel?: (option: Option) => string | ReactNode;
  getOptionValue?: (option: Option) => string;
  isOptionDisabled?: (option: Option, selectValue: Option[]) => boolean;
}

const ReactSelect = <Option,>(props: Props<Option>) => {
  const [selectValue, setSelectValue] = useState(props.value ? props.value.filter(Boolean) : []);

  const getOptions = () => buildOptions(props, selectValue);

  const onSelect = (newValue: Option) => {
    const deselected = isOptionSelected(props, newValue, selectValue);
    const isDisabled = isOptionDisabled(props, newValue, selectValue);

    if (deselected) {
      const candidate = getOptionValue(props, newValue);
      const filteredValue = selectValue.filter((i) => getOptionValue(props, i) !== candidate);
      props.onChange?.([...filteredValue]);
      setSelectValue([...filteredValue]);
    } else if (!isDisabled) {
      props.onChange?.([...selectValue, newValue]);
      setSelectValue([...selectValue, newValue]);
    }
  }

  const renderOptions = () => {
    return getOptions().map(item => (
      <li
        key={item.index}
        className={cn(item.isSelected && "border border-green-600 rounded-lg", item.isDisabled && "cursor-not-allowed text-slate-400 pointer-events-none")}
        onClick={() => onSelect(item.data)}
      >
        {item.label}
      </li>
    ))
  }

  return (
    <div>
      <p>Selected: </p>
      <ul className="space-y-1">
        {renderOptions()}
      </ul>
    </div>
  );
}

export { ReactSelect }
