/**
 * @generated SignedSource<<ad9f3b96c1734df90d7b75a2195237f6>>
 * @relayHash aceb23dfac11bc8232b27bfb4e43819d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aceb23dfac11bc8232b27bfb4e43819d

import { ConcreteRequest, Query } from 'relay-runtime';
export type useRandomFlashcardQuery$variables = {
  skip: boolean;
};
export type useRandomFlashcardQuery$data = {
  readonly randomFlashcard?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "Flashcard";
        readonly audio: string;
        readonly id: string;
        readonly image: string;
        readonly partOfSpeech: string | null;
        readonly phonetic: string | null;
        readonly savedCount: number;
        readonly sentences: ReadonlyArray<{
          readonly text: string;
          readonly translations: {
            readonly vi: string | null;
          } | null;
        }>;
        readonly translations: {
          readonly vi: string | null;
        };
        readonly word: string;
      };
    }>;
  };
};
export type useRandomFlashcardQuery = {
  response: useRandomFlashcardQuery$data;
  variables: useRandomFlashcardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "FlashcardTranslations",
  "kind": "LinkedField",
  "name": "translations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vi",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 50
          }
        ],
        "concreteType": "FlashcardsConnection",
        "kind": "LinkedField",
        "name": "randomFlashcard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlashcardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flashcard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "word",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "savedCount",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audio",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phonetic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partOfSpeech",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SentenceSchema",
                    "kind": "LinkedField",
                    "name": "sentences",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "randomFlashcard(first:50)"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRandomFlashcardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRandomFlashcardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "aceb23dfac11bc8232b27bfb4e43819d",
    "metadata": {},
    "name": "useRandomFlashcardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fe84b6eb5756a30a3b078df2f72bd868";

export default node;
