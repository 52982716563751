import { useEffect, useRef, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { FiSettings } from "react-icons/fi"
import { RiLogoutCircleRLine } from "react-icons/ri"
import { Link, useNavigate } from "react-router-dom"
import { useModalStore } from "../../store/modalStore"
import { useAccessTokenData, useAuthActions } from "../../store/authStore"
import { RxAvatar } from "react-icons/rx"
import { isChromeStorageLocalSetSupported } from "@/utils/isChromeStorageLocalSetSupported"

const UserStatusBar: React.FC = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const openModal2 = useModalStore((state) => state.openModal2)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const accessTokenData = useAccessTokenData()
  const { clearTokens } = useAuthActions()
  const nameOfUser =
    accessTokenData?.firstName + " " + accessTokenData?.lastName
  const navigate = useNavigate()

  const handleLogout = () => {
    const modalContent = <p>Bạn có chắc chắn muốn thoát tài khoản</p>

    openModal2(
      modalContent,
      () => {
        navigate("/")
        if (accessTokenData) {
          localStorage.clear()
          clearTokens()

          if (isChromeStorageLocalSetSupported()) {
            chrome.storage.local.clear()
          }
        }
        location.reload()
      },
      () => {
        // Handle cancellation
        console.log("User cancelled logout")
      },
    )
  }

  const handleDropdownToggle = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !(event.target as Element).closest(".user-button-bar")
    ) {
      setIsOpenDropdown(false)
    }
  }

  const handleMenuItemClick = () => {
    setIsOpenDropdown(false)
  }

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick)

    return () => {
      window.removeEventListener("click", handleOutsideClick)
    }
  }, [])

  return (
    <div className="relative w-full">
      <button
        className="user-button-bar flex items-center justify-between w-full h-11 px-2 rounded-full border-none bg-background space-x-3 transition-transform duration-300 cursor-pointer z-10 user-select-none focus:outline-none hover:transform hover:scale-105"
        onClick={handleDropdownToggle}>
        <p className="text-2xl leading-none transition-transform duration-300 ml-2">
          <RxAvatar />
        </p>
        <p className="text-lg font-bold text-center text-userNameText whitespace-nowrap overflow-hidden text-ellipsis">
          {nameOfUser}
        </p>
        <span className="mr-5 text-lg text-carretIcon">
          <BiChevronDown />
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`absolute left-0 top-16 w-full py-2 pb-3 bg-background-subdued border-b rounded-b-2xl z-50 transition-all duration-300 overflow-hidden ${
          isOpenDropdown ? "opacity-100 visible" : "opacity-0 invisible"
        }`}>
        <ul className="flex flex-col">
          <li>
            <Link
              to="/setting"
              className="flex items-center gap-4 px-6 py-3 text-lg text-menuDropdownItem no-underline cursor-pointer user-select-none transition-all duration-300 hover:bg-menuDropdownItemBg"
              onClick={handleMenuItemClick}>
              <span className="text-lg text-menuDropdownIcon">
                <FiSettings />
              </span>
              Settings
            </Link>
          </li>
          <li>
            <button
              className="flex items-center gap-4 px-6 py-3 text-lg text-menuDropdownItem no-underline cursor-pointer user-select-none transition-all duration-300 hover:bg-menuDropdownItemBg"
              onClick={() => {
                handleLogout()
                handleMenuItemClick()
              }}>
              <span className="text-lg text-red-600">
                <RiLogoutCircleRLine />
              </span>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { UserStatusBar }
