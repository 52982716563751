/**
 * @generated SignedSource<<39bdf810853eb5b1fb297fe1674f5e39>>
 * @relayHash 3444128e1319a1812b693eaef05aad67
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3444128e1319a1812b693eaef05aad67

import { ConcreteRequest, Query } from 'relay-runtime';
export type useUserFlashcardQuery$variables = {
  skip: boolean;
};
export type useUserFlashcardQuery$data = {
  readonly getUserRandomFlashcards?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "Flashcard";
        readonly audio: string;
        readonly id: string;
        readonly image: string;
        readonly partOfSpeech: string | null;
        readonly phonetic: string | null;
        readonly savedCount: number;
        readonly sentences: ReadonlyArray<{
          readonly text: string;
          readonly translations: {
            readonly vi: string | null;
          } | null;
        }>;
        readonly translations: {
          readonly vi: string | null;
        };
        readonly word: string;
      };
    }>;
  };
};
export type useUserFlashcardQuery = {
  response: useUserFlashcardQuery$data;
  variables: useUserFlashcardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "FlashcardTranslations",
  "kind": "LinkedField",
  "name": "translations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vi",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 50
          }
        ],
        "concreteType": "FlashcardsConnection",
        "kind": "LinkedField",
        "name": "getUserRandomFlashcards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlashcardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flashcard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "word",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "savedCount",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audio",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phonetic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partOfSpeech",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SentenceSchema",
                    "kind": "LinkedField",
                    "name": "sentences",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "getUserRandomFlashcards(first:50)"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserFlashcardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserFlashcardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "3444128e1319a1812b693eaef05aad67",
    "metadata": {},
    "name": "useUserFlashcardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2313fbd79d61fdf3a80959e697d58d8a";

export default node;
