 
import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"
import PushButton from "../../component/Button/PushButton"
import FormItem from "../../component/FormItem"
import Input from "../../component/Input"
import Label from "../../component/Label"
import { FORM_TYPES } from "../../constants/FormTypes"
import useAuthFormStore from "../../store/authFormStore"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForgotPasswordMutation } from "./services/useForgotPasswordMutation"
// import { toast } from "@/components/ui/use-toast"

const SignInSchema = z.object({
  email: z.string().email(),
})

type SignInType = z.infer<typeof SignInSchema>

function ForgotPassword() {
  const { t } = useTranslation()
  const { setFormType } = useAuthFormStore((state) => state)
  // const { closeAuthForm } = useAuthFormStore()
  const form = useForm<SignInType>({ resolver: zodResolver(SignInSchema) })
  const [isSent, setIsSent] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = form

  const [forgotPassword, isSubmitting] = useForgotPasswordMutation()

  const onSubmit: SubmitHandler<SignInType> = ({ email }) => {
    forgotPassword({
      variables: {
        input: {
          email,
        },
      },
      onCompleted(response) {
        const isSuccessful = response.forgotPassword

        if (isSuccessful) {
          console.log('check mail please')
          setIsSent(true)
        }

        // closeAuthForm()
      },
      onError() {
        // toast({
        //   description: error.message,
        // })
      },
    })
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  return (
    <FormProvider {...form}>
      <Title>{t("Forgot password")}</Title>
      {isSent ? 
        <div className="p-10">
          <p className="text-green-600 font-bold">Please check your mail to reset the password!</p>
        </div> : 
        <ElementWrap>
          <FormItem>
            <Label htmlFor="email" name={t("Email") + ":"} />
            <Input
              autoFocus
              type="text"
              id="email"
              placeholder={t("Enter email")}
              errorMsg={errors.email?.message}
              disabled={isSubmitting}
              {...register("email")}
            />
          </FormItem>
          <PushButton isFullWidth={true} height={50} isLoading={isSubmitting} onClick={handleSubmit(onSubmit)}>
            {t("Submit")}
          </PushButton>
        </ElementWrap>
      }
      <MoreInfoWrap>
        <MoreInfoText>
          {t("Do you have not an account yet?")}{" "}
          <TextLink onClick={() => setFormType(FORM_TYPES.REGISTER)}>{t("Sign up now")}</TextLink>
        </MoreInfoText>
        <MoreInfoText>
          {t("Do you have an account?")}{" "}
          <TextLink
            onClick={() => {
              setFormType(FORM_TYPES.LOGIN)
            }}>
            {t("Sign in now")}
          </TextLink>
        </MoreInfoText>
      </MoreInfoWrap>
    </FormProvider>
  )
}

export { ForgotPassword }

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`

const ElementWrap = styled.div`
  margin-top: 64px;
`

const MoreInfoWrap = styled.div`
  margin-top: 34px;
  text-align: center;
`

const MoreInfoText = styled.p`
  font-size: 16px;
`

const TextLink = styled.button`
  color: ${({ theme }) => theme.colors.modalCommonText};
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
`
