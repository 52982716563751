import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Command as CommandPrimitive } from "cmdk"
import { isEmpty } from "lodash-es"
import { Button } from "@/components/ui/button"
import { CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/utils/utils"
import { Check, X } from "lucide-react"
import { BiCaretDown } from "react-icons/bi"
import { useClickAway } from "react-use"

interface GroupBase<Option> {
  readonly options: readonly Option[]
  readonly heading?: string
}

interface FilterableSelectGroupedProps<Option, Group extends GroupBase<Option>> {
  groups: Group[]
  options?: never
}

interface FilterableSelectUngroupedProps<Option> {
  options: Option[]
  groups?: never
}

interface FilterableSelectCommonProps<Option> {
  label?: string
  value?: Option
  error?: string
  clearable?: boolean
  filterable?: boolean
  required?: boolean
  translateError?: boolean
  "data-testid"?: string
  getOptionLabel: (option: Option) => string
  getOptionValue: (option: Option) => string
  formatOptionLabel: (data: Option) => string
  isOptionSelected: (option: Option, selectedValue: Option) => boolean
  onChange?: (value: Option | undefined) => void
}

type FilterableSelectProps<Option, Group extends GroupBase<Option>> =
  | (FilterableSelectGroupedProps<Option, Group> & FilterableSelectCommonProps<Option>)
  | (FilterableSelectUngroupedProps<Option> & FilterableSelectCommonProps<Option>)

// Helper function for test IDs
const normalizeTestId = (s1: string, s2?: string) => {
  return s1 + (s2 || "")
}

const FilterableSelect = <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: FilterableSelectProps<Option, Group>,
) => {
  const {
    label,
    value,
    required,
    translateError,
    groups,
    options,
    error,
    clearable,
    filterable = true,
    "data-testid": dataTestId,
    onChange,
    getOptionLabel,
    getOptionValue,
    formatOptionLabel,
    isOptionSelected,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const resultRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [internalValue, setInternalValue] = useState<Option | null | undefined>(value)
  const [stringValue, setStringValue] = useState<string>(value ? formatOptionLabel(value) : "")
  const { t } = useTranslation()

  useClickAway(inputRef, (event: MouseEvent) => {
    if (isOpen && resultRef.current && !resultRef.current.contains(event.target as HTMLElement)) {
      setIsOpen(false)
    }
  })

  const _getOptionLabel = useCallback((option: Option) => getOptionLabel(option), [getOptionLabel])
  const _getOptionValue = useCallback((option: Option) => getOptionValue(option), [getOptionValue])
  const _isOptionSelected = (option: Option) => internalValue && isOptionSelected(option, internalValue)

  const handleClear = () => {
    setInternalValue(null)
    setStringValue("")
    onChange?.(undefined)
  }

  const renderOption = (option: Option) => {
    const isSelected = _isOptionSelected(option)
    const label = _getOptionLabel(option)
    const value = _getOptionValue(option)

    return (
      <CommandItem
        key={value}
        data-testid={dataTestId ? normalizeTestId(dataTestId, "-item") : undefined}
        className={cn(
          "cursor-pointer",
          "data-[selected='true']:bg-primary-hovered",
          isSelected && "bg-primary-surface text-on-primary-surface pointer-events-none",
        )}
        onSelect={() => {
          setInternalValue(option)
          setStringValue(formatOptionLabel(option))
          setIsOpen(false)
          onChange?.(option)
        }}>
        {label}
        {isSelected && (
          <span className="absolute right-2 flex h-6 w-6 items-center justify-center">
            <Check className="text-on-primary-surface" />
          </span>
        )}
      </CommandItem>
    )
  }

  // Render grouped options
  const renderGroupedOptions = () => {
    return groups?.map((group, idx) => (
      <CommandGroup
        key={idx}
        heading={group.heading}
        data-testid={dataTestId ? normalizeTestId(dataTestId, "-group") : undefined}
        className="px-2 pb-2">
        {group.options.map(renderOption)}
      </CommandGroup>
    ))
  }

  // Render ungrouped options
  const renderUngroupedOptions = () => {
    return options?.map(renderOption)
  }

  // Decide which render function to use based on props
  const renderOptions = () => {
    if (groups && "groups" in props && groups.length > 0) {
      return renderGroupedOptions()
    } else if (options && "options" in props && options.length > 0) {
      return renderUngroupedOptions()
    } else {
      return null
    }
  }

  const [shouldFilter, setShouldFilter] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      if (internalValue && stringValue !== _getOptionLabel(internalValue)) {
        setStringValue(_getOptionLabel(internalValue))
      } else if (!isEmpty(stringValue) && !internalValue) {
        setStringValue("")
      }
    }
  }, [_getOptionLabel, internalValue, isOpen, stringValue])

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} modal>
      <CommandPrimitive
        className="relative"
        shouldFilter={shouldFilter}
        data-testid={dataTestId ? normalizeTestId(dataTestId) : undefined}>
        <PopoverTrigger asChild>
          <div className="relative">
            <CommandPrimitive.Input
              className={cn("pr-20", error && "!outline-critical !outline-[2px]")}
              value={stringValue}
              data-testid={dataTestId ? normalizeTestId(dataTestId, "-input") : undefined}
              onValueChange={(search) => {
                setStringValue(search)
                if (filterable) setShouldFilter(true)
                if (!isOpen) setIsOpen(true)
              }}
              ref={inputRef}
              onClick={() => {
                if (!isOpen) setIsOpen(true)
                if (filterable) setShouldFilter(false)
              }}
              placeholder=" "
            />
            {label && (
              <label
                className={cn(
                  "pointer-events-none absolute left-4 top-1 cursor-text text-xs text-on-surface-subdued transition-all peer-placeholder-shown:top-3.5 peer-placeholder-shown:text-sm peer-focus:top-1 peer-focus:text-xs peer-focus:text-primary",
                  error && "text-critical peer-focus:text-critical peer-placeholder-shown:text-on-surface-subdued",
                )}>
                {label}
                {required && "*"}
              </label>
            )}
            {clearable && stringValue && (
              <Button
                data-testid={dataTestId ? normalizeTestId(dataTestId, "-clear-button") : undefined}
                className="hover:bg-surface-hovered absolute right-10 top-6 -translate-y-1/2 px-2.5"
                onClick={(e) => {
                  e.preventDefault()
                  handleClear()
                }}>
                <X className="size-5 text-on-surface-subdued" />
              </Button>
            )}
            <div className="pointer-events-none absolute right-1 top-6 -translate-y-1/2 p-2">
              <BiCaretDown className="h-6 w-6 text-on-surface-subdued" />
            </div>
            {error && <p> {translateError ? t(error) : error}</p>}
          </div>
        </PopoverTrigger>
        <PopoverContent
          ref={resultRef}
          className="shadow-4 z-50 w-[var(--radix-popper-anchor-width)] rounded-xl bg-surface px-0 py-2 outline-none"
          align="start"
          onOpenAutoFocus={(e) => e.preventDefault()}>
          <CommandList className="max-h-none px-0">
            <CommandEmpty
              data-testid={dataTestId ? normalizeTestId(dataTestId, "-empty") : undefined}
              className="px-4 py-2 text-sm">
              {t("common.message.noResultFound")}
            </CommandEmpty>
            <ScrollArea>
              <div className="max-h-80 divide-y-[1px]">{renderOptions()}</div>
            </ScrollArea>
          </CommandList>
        </PopoverContent>
      </CommandPrimitive>
    </Popover>
  )
}

export { FilterableSelect }
