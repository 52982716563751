import { createHashRouter } from "react-router-dom"
import { AppLoader } from "./AppLoader"
import NotFound from "./pages/NotFound"
import Poc from "./pages/Poc"
import { SettingPage } from "./pages/Setting/Setting"
import { VocabList } from "./pages/VocabList"
import { About } from "./pages/About"
import { UserPage } from "./pages/User/page"
import { DefaultLayout } from "./layout/Default"
import { Dashboard } from "./pages/Dashboard"
import { Bookmark } from "./pages/Bookmark"
import { PublicLayout } from "./layout/Public"
import { ResetPassword } from "./pages/ResetPassword"
import { RootBoundary } from "@/pages/RootBoundary"
import { Statistic } from "@/pages/Statistic"
import Page from "@/app/dashboard/page"
import { Store } from "@/pages/Store"

const router = createHashRouter([
  {
    path: "/",
    element: <AppLoader />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: "/",
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: "bookmark",
            element: <Bookmark />,
          },
          {
            path: "statistic",
            element: <Statistic />,
          },
          {
            path: "/setting",
            element: <SettingPage />,
          },
          {
            path: "store",
            element: <Store />,
          },
          {
            path: "vocab-list",
            element: <VocabList />,
          },
          {
            path: "user",
            element: <UserPage />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "/poc",
            caseSensitive: true,
            element: <Poc />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
      {
        path: "/",
        element: <PublicLayout />,
        children: [
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
  {
    path: "page",
    element: <Page />,
  }
])

export { router }
