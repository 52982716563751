import Loading from "@/layout/Loading"
import { Suspense, useState } from "react"
import { List } from "@/pages/Bookmark/list"
import { QueryOptions } from "@/types/QueryOptions"
import { Filter } from "@/pages/Bookmark/filter"

const Bookmark = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<QueryOptions>({})
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <div className="h-full overflow-hidden p-10 pb-48">
      <Filter
        onRefresh={() => {
          setRefreshedQueryOptions((prev) => ({
            fetchKey: Number(prev?.fetchKey ?? 0) + 1,
            fetchPolicy: "network-only",
          }))
        }}
        onSearch={(searchTerm) => setSearchTerm(searchTerm)}
      />
      <Suspense fallback={<Loading />}>
        <List searchTerm={searchTerm} options={refreshedQueryOptions} />
      </Suspense>
    </div>
  )
}

export { Bookmark }
