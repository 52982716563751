const lightTheme = {
  colors: {
    background: '#fff',
    sidebarBackground: '#f5f7f9',
    flashCardBg: '#f8f8f8',
    flashCardShadow: 'rgba(222, 222, 222, 0.56)',
    flashCardText: '#000',

    pageTitleText: '#a6a6a6;',
    pageTitleLine: '#eaeaea;',

    topbarBg: '#fff',
    topbarBorder: '#EAEAEA',
    topbarLine: '#EAEAEA',
    searchBg: '#F5F7F9',
    searchText: '#C0C7CE',
    searchIcon: '#C0C7CE',
    bellBg: '#F5F7F9',
    bellText: '#575757',

    menuText: '#000',
    tipboxBg: '#fff',
    recentTitleText: '#2B2B2B',
    recentTitleLine: '#eaeaea',
    recentCardBg: '#F5F7F9',
    recentText: '#000',
    recentBorder: '#000',
    recentShadow: '#c7c7c7',
    btnText: '#fff',
    btnHoverText: '#0370f4',
    btnPrimaryBg: '#0370f4',
    btnBorder: '#0370f4',
    btnOutlineLine: '#0370f4',
    btnOutlineText: '#0370f4',
    btnOutlineHoverText: '#fff',

    btnPushBg: '#0370f4',
    btnPushEdge: '#1059b1',
    btnPushText: '#fff',

    additionalBoxBg: '#fff',
    additionalBoxLine: '#EAEAEA',
    additionalBoxText1: '#000',
    additionalBoxText2: '#000',
    iconBg: '#f0f0f0',
    icon: '#333',
    iconBgHover: '#fff',
    iconBgLineHover: '#333',

    boxSettingBg: '#f5f7f9',
    boxSettingBorder: '#dcdcdc',
    boxTitleBorder: '#e1e1e1',
    boxTitleText: '#5e5e5e',

    labelText: '#000',
    inputBg: '#fff',
    inputText: '#000',
    inputBorder: '#ebebeb',
    carretIcon: '#000',

    optionItemBg: '#fff',
    optionItemHoverBg: '#d0eaff',
    optionItemBorder: '#ebebeb',
    currentSelectedText: '#000',

    modalBg: '#fff',
    modalCommonText: '#000',
    modalShadow: '#434343',

    userBarBg: '#F1F1F1',
    userNameText: '#464646',
    menuDropdownBg: '#E7E7E7',
    menuDropdownIcon: '#4B5759',
    menuDropdownItem: '#000',
    menuDropdownItemBg: '#F5F7F9;',

    modal2Bg: '#fff',
    modal2Title: '#000',
    modal2Desc: '#000',
    modal2Shadow: '#787878',
  },
};

const darkTheme = {
  colors: {
    background: '#344041',
    sidebarBackground: '#2F3A3B',
    flashCardBg: '#4B5759',
    flashCardShadow: 'rgb(0 0 0 / 24%)',
    flashCardText: '#C0C7CE',

    pageTitleText: '#C0C7CE',
    pageTitleLine: '#4B5759',

    topbarBg: '#2F3A3B',
    topbarBorder: '#2F3A3B',
    topbarLine: '#394648',
    searchBg: '#394648',
    searchText: '#C0C7CE',
    searchIcon: '#C0C7CE',
    bellBg: '#394648',
    bellText: '#C0C7CE',

    menuText: '#C0C7CE',
    tipboxBg: '#394648',
    recentTitleText: '#B5B5B5',
    recentTitleLine: '#4B5759',
    recentCardBg: '#4B5759',
    recentText: '#C0C7CE',
    recentBorder: '#B5B5B5',
    recentShadow: '#2F3A3B',
    btnText: '#C0C7CE',
    btnHoverText: '#C0C7CE',
    btnPrimaryBg: '#5B6668',
    btnBorder: '#C0C7CE',
    btnOutlineLine: '#5B6668',
    btnOutlineText: '#C0C7CE',
    btnOutlineHoverText: '#C0C7CE',

    btnPushBg: '#5B6668',
    btnPushEdge: '#4B5759',
    btnPushText: '#C0C7CE',

    additionalBoxBg: '#2F3A3B',
    additionalBoxLine: 'transparent',
    additionalBoxText1: '#C0C7CE',
    additionalBoxText2: '#C0C7CE',

    iconBg: '#4B5759',
    icon: '#C0C7CE',
    iconBgHover: 'transparent',
    iconBgLineHover: '#4B5759',

    boxSettingBg: '#4B5759',
    boxSettingBorder: '#2F3A3B',
    boxTitleBorder: '#e1e1e1',
    boxTitleText: '#C0C7CE',

    labelText: '#C0C7CE',
    inputBg: '#394648',
    inputText: '#C0C7CE',
    inputBorder: '#394648',
    carretIcon: '#C0C7CE',

    optionItemBg: '#a6a6a6',
    optionItemHoverBg: '#C0C7CE',
    optionItemBorder: '#394648',
    currentSelectedText: '#C0C7CE',

    modalBg: '#4B5759',
    modalCommonText: '#C0C7CE',
    modalShadow: '#000',

    userBarBg: '#4B5759',
    userNameText: '#C0C7CE',
    menuDropdownBg: '#434f50',
    menuDropdownIcon: '#8f8f8f',
    menuDropdownItem: '#ececec',
    menuDropdownItemBg: '#4b5759;',

    modal2Bg: '#4b5759',
    modal2Title: '#c0c7ce',
    modal2Desc: '#c0c7ce',
    modal2Shadow: 'rgb(0 0 0 / 24%)',
  },
};

export { lightTheme, darkTheme };
