import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface ThemeModeStore {
  isDarkMode: boolean
  toggleThemeMode: () => void
}

export const useDarkModeStore = create<ThemeModeStore>()(
  persist(
    (set) => ({
      isDarkMode: true,
      toggleThemeMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
    }),
    {
      name: "theme-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
