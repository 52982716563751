import { Outlet } from "react-router-dom"

const PublicLayout = () => {
  return (
    <div className="grid">
      <Outlet />
    </div>
  )
}

export { PublicLayout }
