 
import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"
import PushButton from "../../component/Button/PushButton"
import FormItem from "../../component/FormItem"
import Input from "../../component/Input"
import Label from "../../component/Label"
import { FORM_TYPES } from "../../constants/FormTypes"
import useAuthFormStore from "../../store/authFormStore"
import { useSignInRelayMutation } from "./mutations/useSignInRelayMutation"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuthActions } from "../../store/authStore"
import { toast } from "@/components/ui/use-toast"
import { isChromeStorageLocalSetSupported } from "@/utils/isChromeStorageLocalSetSupported"

const SignInSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6, {
    message: "Password at least",
  }),
})

type SignInType = z.infer<typeof SignInSchema>

function SignIn() {
  const { t } = useTranslation()
  const { setFormType } = useAuthFormStore((state) => state)
  const { closeAuthForm } = useAuthFormStore()
  const form = useForm<SignInType>({ resolver: zodResolver(SignInSchema) })
  const { setAccessToken, setRefreshToken } = useAuthActions() // there is a problem here

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = form

  const [signIn, isSigning] = useSignInRelayMutation()
  const [hasError, setHasError] = useState(false)

  const onSubmit: SubmitHandler<SignInType> = ({ email, password }) => {
    setHasError(false)
    signIn({
      variables: {
        input: {
          email,
          password,
        },
      },
      onCompleted(response, errors) {
        if (response.login.token) {
          const { refreshToken, token, user } = response.login

          const name = `${user.firstName} ${user.lastName}` 

          // Set to auth store
          setAccessToken(token)
          setRefreshToken(refreshToken)
          /// End
          closeAuthForm()

          if (isChromeStorageLocalSetSupported()) {
            chrome.storage.local.set({ authToken: token, name }, () => {
            });
          }
            
          location.reload()
        } 
        else if (errors) {
          console.log(errors)
        }
      },
      onError(error) {
        setHasError(true)
        toast({
          description: error.message,
        })
      },
    })
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  return (
    <FormProvider {...form}>
      <Title>{t("Sign in")}</Title>
      <ElementWrap>
        <FormItem>
          <Label htmlFor="email" name={t("Email") + ":"} />
          <Input
            autoFocus
            type="text"
            id="email"
            placeholder={t("Enter email")}
            errorMsg={errors.email?.message}
            disabled={isSigning}
            {...register("email")}
          />
        </FormItem>
        <FormItem>
          <Label htmlFor="password" name={t("Password") + ":"} />
          <Input
            id="password"
            type="password"
            placeholder={t("Enter password")}
            errorMsg={errors?.password?.message}
            disabled={isSigning}
            {...register("password")}
          />
        </FormItem>
        {hasError && <p className="my-2 pb-2 text-red-400">Something went wrong. Please try again</p>}
        <PushButton isFullWidth={true} height={50} isLoading={isSigning} onClick={handleSubmit(onSubmit)}>
          {t("Sign in")}
        </PushButton>
      </ElementWrap>
      <MoreInfoWrap>
        <MoreInfoText>
          {t("Do you have not an account yet?")}{" "}
          <TextLink onClick={() => setFormType(FORM_TYPES.REGISTER)}>{t("Sign up now")}</TextLink>
        </MoreInfoText>
        <MoreInfoText>
          <TextLink onClick={() => setFormType(FORM_TYPES.FORGOT)}>{t("Forget password?")}</TextLink>
        </MoreInfoText>
      </MoreInfoWrap>
    </FormProvider>
  )
}

export { SignIn }

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`

const ElementWrap = styled.div`
  margin-top: 64px;
`

const MoreInfoWrap = styled.div`
  margin-top: 34px;
  text-align: center;
`

const MoreInfoText = styled.p`
  font-size: 16px;
`

const TextLink = styled.button`
  color: ${({ theme }) => theme.colors.modalCommonText};
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
`
