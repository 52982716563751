import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { BsBook, BsStar, BsDownload } from "react-icons/bs"

interface VocabSet {
  id: number
  title: string
  description: string
  wordCount: number
  price: number
  author: string
  level: string
  downloads: number
  rating: number
  imageUrl: string
}

const Store = () => {
  const vocabSets: VocabSet[] = [
    {
      id: 1,
      title: "Essential English Words",
      description: "Most common words for daily conversation",
      wordCount: 500,
      price: 0,
      author: "English Academy",
      level: "Beginner",
      downloads: 1200,
      rating: 4.5,
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuxwc_Ix3GxlvVuOkEecWGySuGnp00soTpPQ&s"
    },
  ]

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-background-subdued p-10 pb-48">
      <div className="max-w-7xl mx-auto">
        <h1 className="mb-8 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-dark">
          Vocabulary Store
        </h1>
        
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {vocabSets.map((set) => (
            <li key={set.id} className="group">
              <Card className="h-full overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
                <div className="h-48 overflow-hidden">
                  <img 
                    src={set.imageUrl} 
                    alt={set.title}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                </div>
                <CardHeader>
                  <div className="flex justify-between items-start">
                    <div>
                      <CardTitle className="text-xl font-bold">{set.title}</CardTitle>
                      <CardDescription className="mt-2">{set.description}</CardDescription>
                    </div>
                    <Badge variant="outline" className="bg-primary/10">
                      {set.level}
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <BsBook className="text-primary" />
                      <span>{set.wordCount} words</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <BsStar className="text-yellow-500" />
                      <span>{set.rating}/5.0</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <BsDownload className="text-primary" />
                      <span>{set.downloads} downloads</span>
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="flex justify-between items-center">
                  <span className="text-lg font-bold text-primary">
                    {set.price === 0 ? 'Free' : `${set.price}`}
                  </span>
                  <Button className="transition-all duration-300 hover:scale-105">
                    Add to Library
                  </Button>
                </CardFooter>
              </Card>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export { Store }
