import { graphql, useMutation } from "react-relay"
import { useSignUpMutation as useSignUpMutationProps } from "./__generated__/useSignUpMutation.graphql"

const mutation = graphql`
  mutation useSignUpMutation($input: SignUpUserInput!) {
    signup(signupInput: $input) {
      firstName
      lastName
      email
    }
  }
`

export const useSignUpMutation = () => {
  return useMutation<useSignUpMutationProps>(mutation)
}
