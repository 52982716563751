import "./index.css"
import "./styles/globals.css"
import "./i18next"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App.tsx"
import { Toaster } from "./components/ui/toaster.tsx"

// import * as Sentry from "@sentry/react"

// Sentry.init({
//   dsn: "https://22f9166972dfcaf2f81f8780de17423a@o4508047710945280.ingest.de.sentry.io/4508047929180240",
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
    <Toaster />
  </React.StrictMode>,
)
