import { Button } from "@/components/ui/button"
import { BiHomeCircle } from "react-icons/bi"
import { MdOutlineBugReport } from "react-icons/md"
import { RxReload } from "react-icons/rx"
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "@/components/ui/use-toast"
import { useReportIssueMutation } from "@/pages/RootBoundary/services/useReportIssueMutation"
import { useState } from "react"

function RootBoundary() {
  const error = useRouteError()
  const navigate = useNavigate()
  const [reportIssue, isLoading] = useReportIssueMutation()
  const [value, setValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesn't exist!</div>
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>
    }

    if (error.status === 418) {
      return <div>🫖</div>
    }
  }

  const handleRestartApp = () => {
    localStorage.clear()
    navigate("/")
    window.location.href = "/"
  }

  const handleSubmit = () => {
    reportIssue({
      variables: {
        input: {
          description: value,
        },
      },
      onCompleted: () => {
        toast({
          title: "Issue reported",
          description: "Thank you for reporting the issue. We will look into it.",
        })
        setValue("")
        setIsOpen(false)
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        })
      },
    })
  }

  return (
    <div className="pt-20 text-center">
      <p className="text-xl font-bold">Something went wrong</p>
      <div className="mt-4 space-x-4">
        <Button onClick={() => navigate("/")}>
          <BiHomeCircle className="mr-1" /> Back to home
        </Button>
        <Button onClick={handleRestartApp}>
          <RxReload className="mr-1" />
          Restart the app
        </Button>
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button variant="danger" onClick={() => {
              setIsOpen(true)
            }}>
              <MdOutlineBugReport className="mr-1" />
              Report issue
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Textarea placeholder="What's the issue?"  maxLength={1000} value={value} onChange={(e) => setValue(e.target.value)} />
            <Button isLoading={isLoading} className="mt-2 w-full" onClick={handleSubmit}>Submit</Button>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}

export { RootBoundary }
