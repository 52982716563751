import { create } from "zustand"
import { FORM_TYPE, FORM_TYPES } from "../constants/FormTypes"

type AuthState = {
  formType: FORM_TYPE
  isOpenAuthForm: boolean
  loginFormData: {
    phone: string
    password: string
  }
  registerFormData: {
    phone: string
    password: string
    confirmPassword: string
  }
  setFormType: (type: FORM_TYPE) => void
  updateLoginFormData: (data: Partial<AuthState["loginFormData"]>) => void
  updateRegisterFormData: (data: Partial<AuthState["registerFormData"]>) => void
  clearLoginData: () => void
  clearRegisterData: () => void
  openAuthForm: () => void
  closeAuthForm: () => void
}

const useAuthFormStore = create<AuthState>((set) => ({
  isOpenAuthForm: false,
  formType: FORM_TYPES.LOGIN,
  loginFormData: {
    phone: "",
    password: "",
  },
  registerFormData: {
    phone: "",
    password: "",
    confirmPassword: "",
  },
  setFormType: (type) => set({ formType: type }),
  updateLoginFormData: (data) => set((state) => ({ loginFormData: { ...state.loginFormData, ...data } })),
  updateRegisterFormData: (data) =>
    set((state) => ({
      registerFormData: { ...state.registerFormData, ...data },
    })),
  clearLoginData: () => set({ loginFormData: { phone: "", password: "" } }),
  clearRegisterData: () => set({ registerFormData: { phone: "", password: "", confirmPassword: "" } }),
  openAuthForm: () => set({ isOpenAuthForm: true }),
  closeAuthForm: () => set({ isOpenAuthForm: false }),
}))

export default useAuthFormStore
