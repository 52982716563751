import { FlashcardArea } from "./flashcard-area"
import { InformationSidebar } from "@/pages/Dashboard/information-sidebar"

const Dashboard = () => {
  return (
    <div className="flex h-full">
      <FlashcardArea />
      <InformationSidebar />
    </div>
  )
}

export { Dashboard }
