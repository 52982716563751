/**
 * @generated SignedSource<<062b8615cd7b8f6515358cbb5e32cc74>>
 * @relayHash f4c1ce016ffab6c69ac612a476695334
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f4c1ce016ffab6c69ac612a476695334

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginUserInput = {
  email: string;
  password: string;
};
export type useSignInRelayMutation$variables = {
  input: LoginUserInput;
};
export type useSignInRelayMutation$data = {
  readonly login: {
    readonly refreshToken: string;
    readonly token: string;
    readonly user: {
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
    };
  };
};
export type useSignInRelayMutation = {
  response: useSignInRelayMutation$data;
  variables: useSignInRelayMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "loginUserInput",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginUserResponse",
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSignInRelayMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSignInRelayMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f4c1ce016ffab6c69ac612a476695334",
    "metadata": {},
    "name": "useSignInRelayMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "31d41321378d72c85784844e112ff91e";

export default node;
