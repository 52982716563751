/**
 * @generated SignedSource<<dd63eb5fd1e8163bd84046430176aa3f>>
 * @relayHash 3997e466d09701a47253eab45c6e3d43
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3997e466d09701a47253eab45c6e3d43

import { ConcreteRequest, Query } from 'relay-runtime';
export type RoleName = "Admin" | "Manager" | "Teacher" | "User";
export type useUserQuery$variables = Record<PropertyKey, never>;
export type useUserQuery$data = {
  readonly users: ReadonlyArray<{
    readonly createdAt: string;
    readonly deletedAt: string | null;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly lastSeen: string | null;
    readonly role: {
      readonly name: RoleName;
    };
  }>;
};
export type useUserQuery = {
  response: useUserQuery$data;
  variables: useUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastSeen",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useUserQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "3997e466d09701a47253eab45c6e3d43",
    "metadata": {},
    "name": "useUserQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9441b44e9fcc6336add08a9e8416a0e4";

export default node;
