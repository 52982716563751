import { Suspense } from "react"
import { RouterProvider } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import LoadingComponent from "./layout/Loading"
import { useDarkModeStore } from "./store/useDarkModeStore"
import { darkTheme, lightTheme } from "./styles/theme"
import { router } from "./router"
import { RelayEnvironmentProvider } from "react-relay"
import { RelayEnvironment } from "./RelayEnvironment"

function App() {
  const isDarkMode = useDarkModeStore((state: { isDarkMode: boolean }) => state.isDarkMode) // Use Zustand to get isDarkMode

  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Suspense fallback={<LoadingComponent />}>
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default App
