import { graphql, useMutation } from "react-relay"
import { useRemoveBookmarkMutation as useRemoveBookmarkMutationProps } from "./__generated__/useRemoveBookmarkMutation.graphql"

const mutation = graphql`
  mutation useRemoveBookmarkMutation($id: String!) {
    removeBookmark(id: $id) {
      id
      word
      sourceType
      flashcard {
        id
        word
        translations {
          vi
        }
      }
    }
  }
`

export const useRemoveBookmarkMutation = () => {
  return useMutation<useRemoveBookmarkMutationProps>(mutation)
}
``