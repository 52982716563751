import Loading from "@/layout/Loading"
import { Suspense } from "react"
import { Virtuoso } from "react-virtuoso"
import { useVocabsQuery } from "./useVocabsQuery"

const VocabList = () => {
  const flashcards = useVocabsQuery()

  return (
    <div className="p-10 h-full overflow-hidden">
      <Suspense fallback={<Loading />}>
        <Virtuoso
          className="rounded-md border-2"
          totalCount={flashcards.edges.length}
          data={flashcards.edges}
          itemContent={(idx, flashcard) => (
            <div
              key={flashcard.node.id}
              className="hover:bg-background-subdued hover:cursor-pointer flex space-x-5 text-on-surface-subdued border-b py-2 px-4">
              <p className="w-10 truncate">{idx + 1}</p>
              <p className="w-20 truncate">{flashcard.node.id}</p>
              <p className="min-w-36">{flashcard.node.word}</p>
              <p>{flashcard.node.translations.vi}</p>
            </div>
          )}
        />
      </Suspense>
    </div>
  )
}

export { VocabList }
