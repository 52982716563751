/**
 * @generated SignedSource<<0528488e3aaf2585b155a8b962e83e12>>
 * @relayHash ba041debf087b278f09f425b041832a7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ba041debf087b278f09f425b041832a7

import { ConcreteRequest, Query } from 'relay-runtime';
export type useRememberedUserFlashcardQuery$variables = {
  skip: boolean;
};
export type useRememberedUserFlashcardQuery$data = {
  readonly getAllRememberedUserFlashcards?: ReadonlyArray<{
    readonly createdAt: string;
    readonly flashcard: string;
    readonly id: string;
    readonly rememberedAt: string | null;
  }>;
};
export type useRememberedUserFlashcardQuery = {
  response: useRememberedUserFlashcardQuery$data;
  variables: useRememberedUserFlashcardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserFlashcard",
        "kind": "LinkedField",
        "name": "getAllRememberedUserFlashcards",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rememberedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flashcard",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRememberedUserFlashcardQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRememberedUserFlashcardQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ba041debf087b278f09f425b041832a7",
    "metadata": {},
    "name": "useRememberedUserFlashcardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "79c8c2c47c0690ca20bd83e818531c8e";

export default node;
