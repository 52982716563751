import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiPlusCircle } from "react-icons/bi"
import { styled } from "styled-components"
import PushButton from "../../component/Button/PushButton"
import FormItem from "../../component/FormItem"
import Input from "../../component/Input"
import Label from "../../component/Label"
import { FORM_TYPES } from "../../constants/FormTypes"
import useAuthFormStore from "../../store/authFormStore"
import { useForm } from "react-hook-form"
import { useSignUpMutation } from "./mutations/useSignUpMutation"
import { toast } from "@/components/ui/use-toast"

type Data = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

function SignUpForm() {
  const { t } = useTranslation()
  const setFormType = useAuthFormStore((state) => state.setFormType)
  const closeAuthForm = useAuthFormStore((state) => state.closeAuthForm)
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm<Data>()
  const [step, setStep] = useState(1)
  const [commit, isSigningUp] = useSignUpMutation()

  const onSubmit = (data: Data) => {
    const { email, firstName, lastName, password } = data

    commit({
      variables: {
        input: {
          email,
          firstName,
          lastName,
          password,
        },
      },
      onCompleted() {
        toast({
          description: "Congrats! Account to be created successfully",
        })
        setStep(2)
      },
      onError(err) {
        toast({
          description: err.message,
        })
      },
    })
  }

  const password = watch("password", "")

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title>{t("Sign up a new account")}</Title>
      <ElementWrap>
        {step === 1 && (
          <>
            <FormGroup>
              <FormItem>
                <Label htmlFor="firstName" name={t("First name") + "*:"} />
                <Input
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  placeholder={t("Enter first name")}
                  errorMsg={
                    typeof errors?.firstName?.message === "string"
                      ? errors?.firstName?.message
                      : ""
                  }
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                />
              </FormItem>
              <FormItem>
                <Label htmlFor="lastName" name={t("Last name") + "*:"} />
                <Input
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  placeholder={t("Enter last name")}
                  errorMsg={
                    typeof errors?.lastName?.message === "string"
                      ? errors?.lastName?.message
                      : ""
                  }
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                />
              </FormItem>
            </FormGroup>
            <FormItem>
              <Label htmlFor="email" name={t("Email") + "*:"} />
              <Input
                type="text"
                id="email"
                autoComplete="off"
                placeholder={t("Enter email")}
                errorMsg={
                  typeof errors?.email?.message === "string"
                    ? errors?.email?.message
                    : ""
                }
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Modify this regex as needed for your phone number format
                    message: "Invalid email format",
                  },
                })}
              />
            </FormItem>
            <FormItem>
              <Label name={t("Password") + "*:"} />
              <Input
                type="password"
                id="password"
                placeholder={t("Enter password")}
                errorMsg={
                  typeof errors?.password?.message === "string"
                    ? errors?.password?.message
                    : ""
                }
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
            </FormItem>
            <FormItem>
              <Label name={t("Password confirm") + "*:"} />
              <Input
                type="password"
                id="confirmPassword"
                placeholder={t("Enter password confirm")}
                errorMsg={
                  typeof errors?.confirmPassword?.message === "string"
                    ? errors?.confirmPassword?.message
                    : ""
                }
                {...register("confirmPassword", {
                  required: "Password confirmation is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
            </FormItem>
            <PushButton
              isFullWidth={true}
              height={50}
              icon={<BiPlusCircle />}
              type="submit"
              disabled={isSigningUp}
              isLoading={isSigningUp}>
              {t("Sign up")}
            </PushButton>
          </>
        )}
        {step === 2 && (
          <>
            <CommonText>
              {t("Sign up successfully for ", { email: getValues("email") })}
            </CommonText>
            <PushButton isFullWidth={true} height={50} onClick={closeAuthForm}>
              {t("Finish")}
            </PushButton>
          </>
        )}
      </ElementWrap>
      {step === 1 && (
        <MoreInfoWrap>
          <MoreInfoText>
            {t("Do you have an account?")}{" "}
            <TextLink
              onClick={() => {
                setFormType(FORM_TYPES.LOGIN)
              }}>
              {t("Sign in now")}
            </TextLink>
          </MoreInfoText>
        </MoreInfoWrap>
      )}
    </form>
  )
}

export { SignUpForm }

const CommonText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`

const FormGroup = styled.div`
  display: flex;
  gap: 20px;

  & > div {
    flex: 1;
  }
`

const ElementWrap = styled.div`
  margin-top: 30px;
`

const MoreInfoWrap = styled.div`
  margin-top: 34px;
  text-align: center;
`

const MoreInfoText = styled.p`
  font-size: 16px;
`

const TextLink = styled.button`
  color: ${({ theme }) => theme.colors.modalCommonText};
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
`
