interface FormItemProps {
  children: React.ReactNode
}

const FormItem = ({ children }: FormItemProps) => {
  return <div className="flex flex-col mb-5">{children}</div>
}


export default FormItem
