import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "@/components/ui/chart"
import { useRememberedUserFlashcardQuery } from "@/pages/Statistic/hooks/useRememberedUserFlashcardQuery"
import { useTranslation } from "react-i18next"

const Statistic = () => {
  const { t } = useTranslation()
  const data = useRememberedUserFlashcardQuery()
  const count = data?.length

  const groupedData =
    data?.reduce((acc: Record<string, number>, item) => {
      const date = new Date(item.createdAt).toISOString().split("T")[0]
      acc[date] = (acc[date] || 0) + 1
      return acc
    }, {}) || {}

  const chartData = Object.entries(groupedData)
    .map(([date, count]) => ({
      date,
      remember: count,
    }))
    .sort((a, b) => a.date.localeCompare(b.date))

  const chartConfig = {
    remember: {
      label: "Remember ",
      color: "#99ccfb",
    },
  } satisfies ChartConfig

  return (
    <div className="h-full overflow-hidden p-10 pb-48">
      <p className="mb-8 text-center text-xl">{t("statistic.markedWords", { count })}</p>
      <ChartContainer config={chartConfig} className="max-h-[500px] w-full">
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => new Date(value).toLocaleDateString()}
          />
          <ChartTooltip content={<ChartTooltipContent />} />
          <ChartLegend content={<ChartLegendContent />} />
          <Bar
            dataKey="remember"
            fill="var(--color-remember)"
            barSize={40} // Thinner bars
            radius={4}
          />
        </BarChart>
      </ChartContainer>
    </div>
  )
}

export { Statistic }
