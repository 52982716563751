import { Link, useMatch } from "react-router-dom"
import LogoImage from "./../../images/logo.png"
import { useTranslation } from "react-i18next"
import { getAccessToken, useRole } from "@/store/authStore"
import { AiOutlineDashboard, AiOutlineInfoCircle, AiOutlineSetting, AiOutlineUser } from "react-icons/ai"
import { BsBookmarkHeartFill, BsListColumns } from "react-icons/bs"
import { MenuItem } from "./menu-item"
import { MdOutlineExplore } from "react-icons/md"
import { RxHamburgerMenu } from "react-icons/rx"
import { cn } from "@/utils/utils"
import { TbChartInfographic } from "react-icons/tb"
import { GoContainer } from "react-icons/go";

const ROLES = {
  Admin: "Admin",
  Manager: "Manager",
  Teacher: "Teacher",
  User: "User",
}

const Sidebar = () => {
  const { t } = useTranslation()
  const role = useRole()
  const isDashboardPage = useMatch("/")
  const isAuthenticated = Boolean(getAccessToken())

  return (
    <>
      <div className="absolute left-4 top-4 z-50 md:hidden">
        <RxHamburgerMenu size={25} />
      </div>
      <div className={cn("hidden flex-col bg-background-subdued md:flex")}>
        <h1 className="mx-auto my-10 h-[63px] w-[214px]">
          <Link to="/">
            <img src={LogoImage} />
          </Link>
        </h1>
        <ul className="mt-20 pl-4">
          <MenuItem link="/" icon={<AiOutlineDashboard />} name={t("Dashboard")} />
          {(role === ROLES.User || role === ROLES.Admin) && (
            <MenuItem link="/bookmark" icon={<BsBookmarkHeartFill />} name={t("Bookmark")} />
          )}
          {(role === ROLES.User || role === ROLES.Admin) && isAuthenticated && (
            <MenuItem link="/statistic" icon={<TbChartInfographic />} name={t("Statistic")} />
          )}
          {role === ROLES.Admin && <MenuItem link="/explore" icon={<MdOutlineExplore />} name={t("Explore")} />}
          {role === ROLES.Admin && <MenuItem link="/vocab-list" icon={<BsListColumns />} name={t("Vocab List")} />}
          {(role === ROLES.User || role === ROLES.Admin) && (
            <MenuItem link="/setting" icon={<AiOutlineSetting />} name={t("Setting")} />
          )}
          {role === ROLES.Admin && <MenuItem link="/store" icon={<GoContainer />} name={t("Store")} />}
          {role === ROLES.Admin && <MenuItem link="/user" icon={<AiOutlineUser />} name={t("User")} />}
          <MenuItem link="/about" icon={<AiOutlineInfoCircle />} name={t("Feedback")} />
        </ul>
        {isDashboardPage && (
          <div className="mx-5 mb-10 mt-auto rounded-xl bg-background-subdued p-5 text-center shadow-xl">
            <p className="text-on-surface">
              “Bấm vào vị trí bất
              <br />
              kì trên thẻ từ để lật nghĩa”
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export { Sidebar }
