import { graphql } from "relay-runtime"

const SearchQuery = graphql`
  query searchQuery($text: String!, $targetLang: String!, $sourceLang: String!) {
    search(text: $text, targetLang: $targetLang, sourceLang: $sourceLang) {
      src
      confidence
      sentences {
        trans
        orig
        backend
        src_translit
      }
      dict {
        pos
        terms
        entry {
          word
          reverse_translation
        }
        base_form
        pos_enum
      }
      synsets {
        pos
        base_form
        pos_enum
        entry {
          synonym
          definition_id
          definition
          label_info {
            register
            subject
          }
        }
      }
      definitions {
        pos
        base_form
        pos_enum
        entry {
          gloss
          definition_id
          example
          label_info {
            register
            subject
          }
        }
      }
      examples {
        example {
          text
          definition_id
        }
      }
      ld_result {
        srclangs
        srclangs_confidences
        extended_srclangs
      }
    }
  }
`
export { SearchQuery }
