import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { type useTopicQuery } from "./__generated__/useTopicQuery.graphql"

const query = graphql`
  query useTopicQuery {
    topic {
      id
      name
      language
      translations {
        en
      }
    }
  }
`
const useTopicQuery = () => {
  const { topic } = useLazyLoadQuery<useTopicQuery>(query, {})
  return topic
}

export { useTopicQuery }
