import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { type useVocabsQuery } from "./__generated__/useVocabsQuery.graphql"

const query = graphql`
  query useVocabsQuery {
    flashcards(first: 300) {
      edges {
        node {
          id
          word
          image
          translations {
            vi
          }
        }
      }
    }
  }
`

const useVocabsQuery = () => {
  const { flashcards } = useLazyLoadQuery<useVocabsQuery>(query, {})

  return flashcards
}

export { useVocabsQuery }
