/**
 * @generated SignedSource<<090c4f642c51c614b4d39abe78483ff8>>
 * @relayHash 82e30cd5fa7281c4ee4c263cc68ef0c6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 82e30cd5fa7281c4ee4c263cc68ef0c6

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForgotPasswordInput = {
  email: string;
};
export type useForgotPasswordMutation$variables = {
  input: ForgotPasswordInput;
};
export type useForgotPasswordMutation$data = {
  readonly forgotPassword: string;
};
export type useForgotPasswordMutation = {
  response: useForgotPasswordMutation$data;
  variables: useForgotPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "forgotPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "82e30cd5fa7281c4ee4c263cc68ef0c6",
    "metadata": {},
    "name": "useForgotPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "cc2208f90e3b199a741188c192847f58";

export default node;
