import { graphql, useMutation } from "react-relay"
import { useResetPasswordMutation as useResetPasswordMutationProps } from "./__generated__/useResetPasswordMutation.graphql"

const mutation = graphql`
  mutation useResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`

export const useResetPasswordMutation = () => {
  return useMutation<useResetPasswordMutationProps>(mutation)
}
