import { graphql, useMutation } from "react-relay"
import { useForgotPasswordMutation as useForgotPasswordMutationProps } from "./__generated__/useForgotPasswordMutation.graphql"

const mutation = graphql`
  mutation useForgotPasswordMutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`

export const useForgotPasswordMutation = () => {
  return useMutation<useForgotPasswordMutationProps>(mutation)
}
