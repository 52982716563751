import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useUserFlashcardQuery as useUserFlashcardQueryType } from "./__generated__/useUserFlashcardQuery.graphql"
import { QueryOptions } from "@/types/QueryOptions"

const query = graphql`
  query useUserFlashcardQuery($skip: Boolean!) {
    getUserRandomFlashcards(first: 50) @skip(if: $skip) {
      edges {
        node {
          id
          word
          savedCount
          translations {
            vi
          }
          image
          audio
          phonetic
          partOfSpeech
          sentences {
            text
            translations {
              vi
            }
          }
          __typename
        }
      }
    }
  }
`

const useUserFlashcardQuery = ({ skip, options }: { skip: boolean; options?: QueryOptions }) => {
  const data = useLazyLoadQuery<useUserFlashcardQueryType>(
    query,
    {
      skip: skip ?? false,
    },
    { ...options },
  )

  return data
}

export { useUserFlashcardQuery }
