import { graphql, useMutation } from "react-relay"
import { useCreateRecipeMutation as useCreateRecipeMutationProps } from "./__generated__/useCreateRecipeMutation.graphql"

const mutation = graphql`
  mutation useCreateRecipeMutation($input: CreateRecipeInput!) {
    createRecipe(createRecipeInput: $input) {
      title
      description
      ingredients
    }
  }
`

export const useCreateRecipeMutation = () => {
  return useMutation<useCreateRecipeMutationProps>(mutation)
}
