import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"
import { useUpdateUserSettingMutation as useUpdateUserSettingMutationType } from "./__generated__/useUpdateUserSettingMutation.graphql"

const mutation = graphql`
  mutation useUpdateUserSettingMutation($input: UpdateUserSettingsInput!) {
    updateUserSettings(updateUserSettingsInput: $input) {
      id
      firstName
      lastName
      email
      address
      ip
      active
      role {
        name
      }
      online
      createdAt
      updatedAt
      themeMode
      appLanguage
      learningLanguage
      chosenTopics
    }
  }
`
const useUpdateUserSettingMutation = () => {
  return useMutation<useUpdateUserSettingMutationType>(mutation)
}

export { useUpdateUserSettingMutation }
