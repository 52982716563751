/**
 * @generated SignedSource<<93e3ff9165fa956127dd9ac27ebab05d>>
 * @relayHash 378e3ff326a9677389856554a4d86cc3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 378e3ff326a9677389856554a4d86cc3

import { ConcreteRequest, Query } from 'relay-runtime';
export type useUserBookmarkQuery$variables = {
  skip: boolean;
};
export type useUserBookmarkQuery$data = {
  readonly getUserBookmarks?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "Bookmark";
        readonly content: string | null;
        readonly createdAt: string;
        readonly flashcard: {
          readonly id: string;
          readonly translations: {
            readonly vi: string | null;
          };
          readonly word: string;
        } | null;
        readonly id: string;
        readonly sourceType: string;
        readonly sourceUrl: string | null;
        readonly word: string | null;
      };
    }>;
  } | null;
};
export type useUserBookmarkQuery = {
  response: useUserBookmarkQuery$data;
  variables: useUserBookmarkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "word",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BookmarksEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bookmark",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Flashcard",
            "kind": "LinkedField",
            "name": "flashcard",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FlashcardTranslations",
                "kind": "LinkedField",
                "name": "translations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vi",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserBookmarkQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "getUserBookmarks",
            "args": null,
            "concreteType": "BookmarksConnection",
            "kind": "LinkedField",
            "name": "__zb_getUserBookmarks_connection",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserBookmarkQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "BookmarksConnection",
            "kind": "LinkedField",
            "name": "getUserBookmarks",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "getUserBookmarks(first:200)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "zb_getUserBookmarks",
            "kind": "LinkedHandle",
            "name": "getUserBookmarks"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "378e3ff326a9677389856554a4d86cc3",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "getUserBookmarks"
          ]
        }
      ]
    },
    "name": "useUserBookmarkQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "151bb7b02d39c935636ae324ee4524c3";

export default node;
