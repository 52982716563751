import React, { useRef } from 'react';

interface DateMaskInputProps {
  value: string;
  onChange: (value: string) => void;
}

const DateMaskInput: React.FC<DateMaskInputProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const setCaretPosition = (inputElement: HTMLInputElement, position: number) => {
    window.requestAnimationFrame(() => {
      inputElement.setSelectionRange(position, position);
      inputElement.focus();
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/\D/g, '');
    let caretPosition = e.target.selectionStart ?? 0;

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + '.' + inputValue.slice(2);
      caretPosition++;
    }
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + '.' + inputValue.slice(5);
      caretPosition++;
    }
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10);
    }

    onChange(inputValue);

    if (inputRef.current) {
      setCaretPosition(inputRef.current, caretPosition);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    const caretPosition = inputElement.selectionStart ?? 0;

    if (e.key === 'Backspace' && caretPosition > 0) {
      const newCaretPosition = caretPosition - 1;

      console.log("newCaretPosition: ", newCaretPosition)
      if (inputElement.value[newCaretPosition] === '.') {
        e.preventDefault();
        const newPosition = newCaretPosition;
        setTimeout(() => setCaretPosition(inputElement, newPosition), 0);
      }
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="DD.MM.YYYY"
      maxLength={10}
      className="date-mask-input"
    />
  );
};

export default DateMaskInput;
