import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useUserBookmarkQuery as useUserBookmarkQueryType } from "./__generated__/useUserBookmarkQuery.graphql"
import { QueryOptions } from "@/types/QueryOptions"

export const bookmarkQuery = graphql`
  query useUserBookmarkQuery($skip: Boolean!) {
    getUserBookmarks(first: 200) @connection(key: "zb_getUserBookmarks") @skip(if: $skip) {
      edges {
        node {
          id
          word
          sourceType
          content
          createdAt
          sourceUrl
          __typename
          flashcard {
            id
            word
            translations {
              vi
            }
          }
        }
      }
    }
  }
`

const useUserBookmarkQuery = ({ skip = false, options }: { skip?: boolean; options?: QueryOptions }) => {
  const { getUserBookmarks } = useLazyLoadQuery<useUserBookmarkQueryType>(
    bookmarkQuery,
    {
      skip,
    },
    {
      ...options,
    },
  )

  return getUserBookmarks
}

export { useUserBookmarkQuery }
