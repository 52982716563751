import { AppLanguage, LearningLanguage, ThemeMode } from "./services/__generated__/useUpdateUserSettingMutation.graphql"

const LEARNING_LANGUAGES: {
  value: LearningLanguage
  label: string
}[] = [
  {
    value: "english",
    label: "Tiếng Anh",
  },
]

const APP_LANGUAGES: {
  value: AppLanguage
  label: string
}[] = [
  {
    value: "English",
    label: "Tiếng Anh",
  },
  {
    value: "Vietnamse",
    label: "Tiếng Việt",
  },
]

const APPERANCE_MODES: {
  value: ThemeMode
  label: string
}[] = [
  {
    value: "Light",
    label: "Sáng",
  },
  {
    value: "Dark",
    label: "Tối",
  },
]

export { LEARNING_LANGUAGES, APP_LANGUAGES, APPERANCE_MODES }
