import { Suspense, useEffect, useState } from "react"
import { BiBookOpen } from "react-icons/bi"
import { ImProfile } from "react-icons/im"
import { TbAdjustmentsHorizontal } from "react-icons/tb"
import PushButton from "../../component/Button/PushButton"
import FormItem from "../../component/FormItem"
import Label from "../../component/Label"
import Input from "../../component/Input"
import { useTranslation } from "react-i18next"
import { useTopicQuery } from "./services/useTopicQuery"
import { APP_LANGUAGES, LEARNING_LANGUAGES } from "./constants"
import { useAccessTokenData } from "../../store/authStore"
import { useUpdateUserSettingMutation } from "./services/useUpdateUserSettingMutation"
import {
  LearningLanguage,
  useUpdateUserSettingMutation$variables,
} from "./services/__generated__/useUpdateUserSettingMutation.graphql"
import { AppLanguage } from "../../services/__generated__/useMeQuery.graphql"
import Loading from "../../layout/Loading"
import { useMeQuery } from "../../services/useMeQuery"
import { SettingBox } from "./SettingBox"
import { toast } from "@/components/ui/use-toast"
import { MultiSelect } from "@/components/multiple-select"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useUserSettingsStore } from "../../store/userSettingsStore"

type SettingData = useUpdateUserSettingMutation$variables["input"]

const SettingIncludeFetching = () => {
  const { t, i18n } = useTranslation()
  const userData = useAccessTokenData()
  const me = useMeQuery()

  const topicsData = useTopicQuery()
  const [updateUserSettings, isUpdating] = useUpdateUserSettingMutation()
  const incrementSettingsVersion = useUserSettingsStore((state) => state.incrementSettingsVersion)

  const [learningLanguage, setLearningLanguage] = useState(
    LEARNING_LANGUAGES.find((item) => item.value === me?.learningLanguage) || null,
  )

  const [appLanguage, setAppLanguage] = useState<AppLanguage>(
    APP_LANGUAGES.find((item) => item.value === me?.appLanguage)?.value as AppLanguage,
  )

  useEffect(() => {
    setAppLanguage(APP_LANGUAGES.find((item) => item.value === me?.appLanguage)?.value as AppLanguage)
  }, [me?.appLanguage])

  useEffect(() => {
    setLearningLanguage(LEARNING_LANGUAGES.find((item) => item.value === me?.learningLanguage) || null)
  }, [me?.learningLanguage])

  const [topics, setTopics] = useState(topicsData.filter((item) => me?.chosenTopics?.includes(item.id)))

  const handleLearningSettingSave = () => {
    if (!topics || !topics.length) return

    handleUpdateUserSettings({
      learningLanguage: learningLanguage?.value as LearningLanguage,
      chosenTopics: topics.map((item) => item.id),
    })
  }

  const handleAppLanguageChange = (value: string) => {
    i18n.changeLanguage(value)
    setAppLanguage(value as AppLanguage)
  }

  const handleUpdateUserSettings = (data: SettingData) => {
    updateUserSettings({
      variables: {
        input: { ...data },
      },
      onCompleted() {
        toast({
          description: "Hoàn thành",
        })
        incrementSettingsVersion()
      },
      onError() {
        toast({
          description: "Lỗi đã xảy ra!",
        })
      },
    })
  }

  return (
    <>
      {/* Learning setting */}
      <SettingBox title={t("Learning")} titleIcon={<BiBookOpen />}>
        <FormItem>
          <Label name={t("Topic") + ":"} />
          <MultiSelect
            options={[...topicsData]}
            onChange={setTopics}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            defaultValue={topics}
            placeholder="Select topics"
            animation={2}
            maxCount={3}
          />
        </FormItem>
        <PushButton isLoading={isUpdating} onClick={handleLearningSettingSave}>
          {t("Save")}
        </PushButton>
      </SettingBox>

      {/* System setting */}
      <SettingBox title={t("System")} titleIcon={<TbAdjustmentsHorizontal />}>
        <FormItem>
          <Label name={t("System language") + ":"} />
          <Select value={appLanguage} onValueChange={handleAppLanguageChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent>
              {APP_LANGUAGES.map((language) => {
                return (
                  <SelectItem key={language.value} value={language.value}>
                    {language.label}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </FormItem>
      </SettingBox>

      {/* Account setting */}
      <SettingBox title={t("Profile")} titleIcon={<ImProfile />}>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <FormItem>
              <Label name={t("First name") + ":"} />
              <Input disabled value={userData?.firstName} onChange={() => {}} />
            </FormItem>
            <FormItem>
              <Label name={t("Email address") + ":"} />
              <Input disabled value={userData?.email} onChange={() => {}} />
            </FormItem>
          </div>
          <div>
            <FormItem>
              <Label name={t("Last name") + ":"} />
              <Input disabled value={userData?.lastName} onChange={() => {}} />
            </FormItem>
            <FormItem>
              <Label name={t("Role") + ":"} />
              <Input disabled value={userData?.role} onChange={() => {}} />
            </FormItem>
          </div>
        </div>
      </SettingBox>
    </>
  )
}

const SettingPage = () => {
  return (
    <Suspense fallback={<Loading />}>
      <div className="mx-auto grid grid-cols-3 gap-6 px-9 py-12">
        <SettingIncludeFetching />
      </div>
    </Suspense>
  )
}

export { SettingPage }
