import { graphql, useMutation } from "react-relay"
import { useReportIssueMutation as useReportIssueMutationProps } from "./__generated__/useReportIssueMutation.graphql"

const mutation = graphql`
  mutation useReportIssueMutation($input: ReportIssueInput!) {
    reportIssue(input: $input)
  }
`

export const useReportIssueMutation = () => {
  return useMutation<useReportIssueMutationProps>(mutation)
}
