/**
 * @generated SignedSource<<c7006bf7529bf4c0cc7b62fe0c3d593b>>
 * @relayHash 12a9b379c9c9a46b300da1b240fb4155
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 12a9b379c9c9a46b300da1b240fb4155

import { ConcreteRequest, Query } from 'relay-runtime';
export type useTopicQuery$variables = Record<PropertyKey, never>;
export type useTopicQuery$data = {
  readonly topic: ReadonlyArray<{
    readonly id: string;
    readonly language: string;
    readonly name: string;
    readonly translations: {
      readonly en: string | null;
    };
  }>;
};
export type useTopicQuery = {
  response: useTopicQuery$data;
  variables: useTopicQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Topic",
    "kind": "LinkedField",
    "name": "topic",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Translations",
        "kind": "LinkedField",
        "name": "translations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "en",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTopicQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useTopicQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "12a9b379c9c9a46b300da1b240fb4155",
    "metadata": {},
    "name": "useTopicQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "984856b8d2a7486a50760dac8d1731f4";

export default node;
