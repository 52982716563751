import { create } from 'zustand';

interface ModalState {
  isOpen: boolean;
  modalContent: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void; // Add this property
  openModal2: (
    content: React.ReactNode,
    onConfirm?: () => void,
    onCancel?: () => void
  ) => void;
  closeModal: () => void;
}

export const useModalStore = create<ModalState>((set) => ({
  isOpen: false,
  modalContent: null,
  onConfirm: () => {},
  onCancel: () => {},
  openModal2: (content, onConfirm, onCancel) =>
    set({ isOpen: true, modalContent: content, onConfirm, onCancel }),
  closeModal: () => set({ isOpen: false, modalContent: null }),
}));
