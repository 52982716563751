import Input from "@/component/Input"
import { useSearchParams } from "react-router-dom"
import { useResetPasswordMutation } from "./services/useResetPasswordMutation"
import { Button } from "@/components/ui/button"
import { FormProvider, SubmitHandler, useForm, } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { toast } from "@/components/ui/use-toast"

const ResetPasswordSchema = z.object({
  newPassword: z.string().min(6, "Password must be at least 6 characters long"),
  confirmNewPassword: z.string().min(6, "Password must be at least 6 characters long"),
}).refine((data) => data.newPassword === data.confirmNewPassword, {
  message: "Passwords do not match",
  path: ["confirmNewPassword"],
})

type ResetPasswordSchemaType = z.infer<typeof ResetPasswordSchema>

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const [resetPassword, isSubmitting] = useResetPasswordMutation()
  const [isSuccessful, setIsSuccessful] = useState(false)
  const form = useForm<ResetPasswordSchemaType>({
    resolver: zodResolver(ResetPasswordSchema)
  })

  const { handleSubmit, register, formState: { errors } } = form
  
  const onSubmit: SubmitHandler<ResetPasswordSchemaType> = (data) => {
    setIsSuccessful(false)
    if (token) {
      resetPassword({
        variables: {
          input: {
            newPassword: data.newPassword,
            resetToken: token
          }
        },
        onCompleted(response) {
          if (response.resetPassword) {
            console.log('reset password succesfully')
            setIsSuccessful(true)
          }
        },
        onError() {
          toast({
            description: "Something went wrong! Please try again later."
          })
        },
      })
    }
  }

  return (
    <div className="p-10 w-[370px] m-auto">
      <h2>Reset your password</h2>
      <div className="flex flex-col gap-2 mt-2">
        {isSuccessful ? 
          <p>Congrats! You've changed the password succesfully. Please login to use!</p> :
          <FormProvider {...form}>
            <Input type="password" placeholder="New password" {...register("newPassword")} errorMsg={errors.newPassword?.message} />
            <Input type="password" placeholder="Conirm new password" {...register("confirmNewPassword")} errorMsg={errors.confirmNewPassword?.message} />
            <Button type="submit" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>Submit</Button>
          </FormProvider>
        }
      </div>
    </div>
  )
}

export  {ResetPassword}
