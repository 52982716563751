import { useMarkFlashcardAsRememberedMutation as useMarkFlashcardAsRememberedMutationProps } from "@/pages/Dashboard/hooks/__generated__/useMarkFlashcardAsRememberedMutation.graphql"
import { graphql, useMutation } from "react-relay"

const mutation = graphql`
  mutation useMarkFlashcardAsRememberedMutation($input: UserFlashcardInput!) {
    # @prependNode(connections: $connections, edgeTypeName: "BookmarksEdge")
    markFlashcardAsRemembered(input: $input) {
      id
      user
      flashcard
      isRemembered
      rememberedAt
      createdAt
      updatedAt
    }
  }
`

export const useMarkFlashcardAsRememberedMutation = () => {
  return useMutation<useMarkFlashcardAsRememberedMutationProps>(mutation)
}
