import { create } from "zustand"

interface UserSettingsStore {
  settingsVersion: number
  incrementSettingsVersion: () => void
  resetSettingsVersion: () => void
}

export const useUserSettingsStore = create<UserSettingsStore>((set) => ({
  settingsVersion: 0,
  incrementSettingsVersion: () => set((state) => ({ settingsVersion: state.settingsVersion + 1 })),
  resetSettingsVersion: () => set({ settingsVersion: 0 }),
}))
