import { cva, type VariantProps } from "class-variance-authority"
import { CheckIcon, XCircle, ChevronDown, XIcon } from "lucide-react"

import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command"
import { cn } from "@/utils/utils"
import { ButtonHTMLAttributes, KeyboardEvent, ReactNode, useState } from "react"

interface CategorizedOption<Option> {
  type: "option"
  data: Option
  isDisabled: boolean
  isSelected: boolean
  label: string | ReactNode
  value: string
  index: number
}

const multiSelectVariants = cva(
  "m-1 bg-background-subdued transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110",
  {
    variants: {
      variant: {
        default: "border-foreground/10 text-foreground bg-card hover:bg-card/80",
        secondary: "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/80 border-transparent",
        inverted: "inverted",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

const getOptionLabelBuiltin = <Option,>(option: Option): string => (option as { label?: unknown }).label as string

const getOptionValueBuiltin = <Option,>(option: Option): string => (option as { value?: unknown }).value as string

const isOptionSelected = <Option,>(
  props: MultiSelectProps<Option>,
  option: Option,
  selectValue: readonly Option[],
): boolean => {
  if (selectValue.includes(option)) return true

  const candidate = getOptionValue(props, option)
  return selectValue.some((i) => getOptionValue(props, i) === candidate)
}

const isOptionDisabled = <Option,>(props: MultiSelectProps<Option>, option: Option, selectValue: Option[]): boolean =>
  typeof props.isOptionDisabled === "function" ? props.isOptionDisabled(option, selectValue) : false

const toCategorizedOption = <Option,>(
  props: MultiSelectProps<Option>,
  option: Option,
  selectValue: Option[],
  index: number,
): CategorizedOption<Option> => ({
  type: "option",
  data: option,
  isDisabled: isOptionDisabled(props, option, selectValue),
  isSelected: isOptionSelected(props, option, selectValue),
  label: getOptionLabel(props, option),
  value: getOptionValue(props, option),
  index,
})

const getOptionLabel = <Option,>(props: MultiSelectProps<Option>, data: Option): string | ReactNode =>
  props.getOptionLabel ? props.getOptionLabel(data) : getOptionLabelBuiltin(data)

const getOptionValue = <Option,>(props: MultiSelectProps<Option>, data: Option): string =>
  props.getOptionValue ? props.getOptionValue(data) : getOptionValueBuiltin(data)

const buildOptions = <Option,>(props: MultiSelectProps<Option>, selectValue: Option[]) =>
  props.options.map((option, idx) => toCategorizedOption(props, option, selectValue, idx))

interface MultiSelectProps<Option = unknown>
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "value" | "defaultValue" | "onChange">,
    VariantProps<typeof multiSelectVariants> {
  value?: Option[]
  options: Option[]
  onChange?: (newValue: Option[]) => void
  defaultValue: Option[]
  placeholder?: string
  /**
   * Animation duration in seconds for the visual effects (e.g., bouncing badges).
   * Optional, defaults to 0 (no animation).
   */
  animation?: number
  maxCount?: number
  modalPopover?: boolean
  asChild?: boolean
  className?: string

  getOptionLabel?: (option: Option) => string | ReactNode
  getOptionValue?: (option: Option) => string
  isOptionDisabled?: (option: Option, selectValue: Option[]) => boolean
}

const MultiSelect = <Option,>(props: MultiSelectProps<Option>) => {
  const {
    options,
    onChange,
    variant,
    placeholder = "Select options",
    animation = 0,
    maxCount = 3,
    modalPopover = false,
    className,
    // ...rest
  } = props

  const [selectValue, setSelectValue] = useState<Option[]>(props.defaultValue ? props.defaultValue.filter(Boolean) : [])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  // const [isAnimating, setIsAnimating] = useState(false);

  // useEffect(() => {
  //   if (JSON.stringify(selectValue) !== JSON.stringify(defaultValue)) {
  //     setSelectValue(defaultValue);
  //   }
  // }, [defaultValue, selectValue]);

  const getOptions = () => buildOptions(props, selectValue)

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsPopoverOpen(true)
    } else if (event.key === "Backspace" && !event.currentTarget.value) {
      const newSelectedValues = [...selectValue]
      newSelectedValues.pop()
      setSelectValue(newSelectedValues)
      onChange?.(newSelectedValues)
    }
  }

  const toggleOption = (value: Option) => {
    const newSelectedValues = selectValue.includes(value)
      ? selectValue.filter((v) => v !== value)
      : [...selectValue, value]
    setSelectValue(newSelectedValues)

    console.log("@newSelectedValues: ", newSelectedValues)
    onChange?.(newSelectedValues)
  }

  const handleClear = () => {
    setSelectValue([])
    onChange?.([])
  }

  const handleTogglePopover = () => {
    setIsPopoverOpen((prev) => !prev)
  }

  const clearExtraOptions = () => {
    const newSelectedValues = selectValue.slice(0, maxCount)
    setSelectValue(newSelectedValues)
    onChange?.(newSelectedValues)
  }

  const toggleAll = () => {
    // if (selectedValues.length === options.length) {
    //   handleClear();
    // } else {
    //   const allValues = options.map((option) => option.value);
    //   setSelectedValues(allValues);
    //   onValueChange(allValues);
    // }
  }

  const renderOptions = () => {
    return getOptions().map((option, idx) => {
      return (
        <CommandItem
          key={idx}
          onSelect={() => {
            console.log("selecting ...")
            toggleOption(option.data)
          }}
          className="cursor-pointer data-[selected='true']:bg-primary-hovered">
          <div
            className={cn(
              "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
              option.isSelected ? "text-primary-foreground bg-primary" : "opacity-50 [&_svg]:invisible",
            )}>
            <CheckIcon className="h-4 w-4" />
          </div>
          <span>{option.label}</span>
        </CommandItem>
      )
    })
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={modalPopover}>
      <PopoverTrigger asChild>
        <Button
          // ref={ref}
          // {...rest}
          onClick={handleTogglePopover}
          className={cn(
            "flex h-auto min-h-10 w-full items-center justify-between rounded-md border bg-inherit p-1 hover:bg-inherit",
            className,
          )}>
          {selectValue.length > 0 ? (
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-wrap items-center">
                {selectValue.slice(0, maxCount).map((value, idx) => {
                  return (
                    <Badge
                      key={idx}
                      className={cn(
                        // isAnimating ? "animate-bounce" : "",
                        multiSelectVariants({ variant, className }),
                      )}
                      style={{ animationDuration: `${animation}s` }}>
                      {getOptionLabel(props, value)}
                      <XCircle
                        className="ml-2 h-4 w-4 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation()
                          toggleOption(value)
                        }}
                      />
                    </Badge>
                  )
                })}
                {selectValue.length > maxCount && (
                  <Badge
                    className={cn(
                      "text-foreground border-foreground/1 bg-transparent hover:bg-transparent",
                      // isAnimating ? "animate-bounce" : "",
                      multiSelectVariants({ variant, className }),
                    )}
                    style={{ animationDuration: `${animation}s` }}>
                    {`+ ${selectValue.length - maxCount} more`}
                    <XCircle
                      className="ml-2 h-4 w-4 cursor-pointer"
                      onClick={(event) => {
                        event.stopPropagation()
                        clearExtraOptions()
                      }}
                    />
                  </Badge>
                )}
              </div>
              <div className="flex items-center justify-between">
                <XIcon
                  className="text-muted-foreground mx-2 h-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleClear()
                  }}
                />
                <Separator orientation="vertical" className="flex h-full min-h-6" />
                <ChevronDown className="text-muted-foreground mx-2 h-4 cursor-pointer" />
              </div>
            </div>
          ) : (
            <div className="mx-auto flex w-full items-center justify-between">
              <span className="text-muted-foreground mx-3 text-sm">{placeholder}</span>
              <ChevronDown className="text-muted-foreground mx-2 h-4 cursor-pointer" />
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="z-20 w-full bg-background-subdued p-0"
        align="start"
        onEscapeKeyDown={() => setIsPopoverOpen(false)}>
        <Command>
          <CommandInput placeholder="Search..." onKeyDown={handleInputKeyDown} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              <CommandItem key="all" onSelect={toggleAll}>
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                    selectValue.length === options.length
                      ? "text-primary-foreground bg-primary"
                      : "opacity-50 [&_svg]:invisible",
                  )}>
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span>(Select All)</span>
              </CommandItem>
              {renderOptions()}
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup>
              <div className="flex items-center justify-between">
                {selectValue.length > 0 && (
                  <>
                    <CommandItem onSelect={handleClear} className="flex-1 cursor-pointer justify-center">
                      Clear
                    </CommandItem>
                    <Separator orientation="vertical" className="flex h-full min-h-6" />
                  </>
                )}
                <CommandSeparator />
                <CommandItem onSelect={() => setIsPopoverOpen(false)} className="flex-1 cursor-pointer justify-center">
                  Close
                </CommandItem>
              </div>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
      {/* {animation > 0 && selectedValues.length > 0 && (
          <WandSparkles
            className={cn(
              "cursor-pointer my-2 text-foreground bg-background w-3 h-3",
              isAnimating ? "" : "text-muted-foreground"
            )}
            onClick={() => setIsAnimating(!isAnimating)}
          />
        )} */}
    </Popover>
  )
}

export { MultiSelect }
