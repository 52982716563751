/**
 * @generated SignedSource<<d9567da271058bb616335a8f90992145>>
 * @relayHash 58c8974e230c3d82f0f40d793c166e00
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 58c8974e230c3d82f0f40d793c166e00

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportIssueInput = {
  description: string;
};
export type useReportIssueMutation$variables = {
  input: ReportIssueInput;
};
export type useReportIssueMutation$data = {
  readonly reportIssue: string;
};
export type useReportIssueMutation = {
  response: useReportIssueMutation$data;
  variables: useReportIssueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "reportIssue",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReportIssueMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReportIssueMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "58c8974e230c3d82f0f40d793c166e00",
    "metadata": {},
    "name": "useReportIssueMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "feb3eacbe540621a4f6e8eb654f8461e";

export default node;
