import { graphql, useSubscription } from "react-relay"

const subscription = graphql`
  subscription useRecipeSubscription {
    recipeCreated {
      id
      title
    }
  }
`

function useRecipeSubscription() {
  return useSubscription({
    subscription,
    variables: {},
    onNext(response) {
      console.log("@response: ", response)
    },
    onCompleted() {
      console.log("onCompleted")
    },
    onError(error) {
      console.log("onError: ", error)
    },
  })
}

export { useRecipeSubscription }
