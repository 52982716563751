import { styled } from "styled-components"
import { FORM_TYPES } from "../constants/FormTypes"
import LoginBg from "../images/LoginBg.png"
import useAuthFormStore from "../store/authFormStore"
import { SignUpForm } from "../modules/SignUp/SignUpForm"
import { SignIn } from "../modules/SignIn"
import { ForgotPassword } from "@/modules/forgot-password"

function AuthForm() {
  const formType = useAuthFormStore((state) => state.formType)

  return (
    <FormWrapper>
      <BGIllustrator>
        <img src={LoginBg} className="object-cover h-full"></img>
      </BGIllustrator>
      <FormContent>
        {formType === FORM_TYPES.LOGIN && <SignIn />}
        {formType === FORM_TYPES.REGISTER && <SignUpForm />}
        {formType === FORM_TYPES.FORGOT && <ForgotPassword />}
      </FormContent>
    </FormWrapper>
  )
}

export default AuthForm

const FormWrapper = styled.div`
  display: flex;
`

const BGIllustrator = styled.div`
  flex-shrink: 0;
  width: 378px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
`

const FormContent = styled.div`
  flex: 1;
  padding: 40px 50px;
`
